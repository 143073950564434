import { Storage } from 'aws-amplify';

export default async function ListFiles(aPrefix) {
  const files = [];

  let nextToken = null;
  let hasNextToken = false;

  do {
    const options = {
      level: 'public',
      pageSize: 800,
      nextToken: nextToken,
    };
    const response = await Storage.list(aPrefix, options);

    nextToken = response.nextToken || null;

    hasNextToken = Boolean(response.hasNextToken);

    files.push(...response.results);
  } while (hasNextToken);

  return files;
}
