import React, {
  useContext,
  useState,
  Fragment,
  useEffect,
  useRef,
} from 'react';
import {
  Backup,
  Delete,
  Edit,
  Save,
  ExpandMore,
  Cancel,
} from '@mui/icons-material';
import BroadcasterContext from './context';
import * as actions from './actions';
import {
  Accordion,
  AccordionSummary,
  Typography,
  Tooltip,
  AccordionDetails,
  Button,
  TextField,
  Box,
  FormControl,
  IconButton,
  FormControlLabel,
  Switch,
  Snackbar,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  FormGroup,
  Alert,
} from '@mui/material';

import { red, indigo, green, grey, blue } from '@mui/material/colors';

import * as mutations from 'graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import IncTableItem from './incTable';
const XLSX = require('xlsx');
const styles = {
  nameField: {
    flexGrow: 2,
  },
  flexStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  summarySva: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '95%',
  },
  buttons: {
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  svaBox: {
    display: 'flex',
    border: 1,
    borderColor: '#000',
    borderRadius: 1,
    flexDirection: 'row',
  },
  select: {
    minWidth: '320px',
    marginLeft: '15px',
  },
};

const SvaItem = (props) => {
  const { state, dispatch } = useContext(BroadcasterContext);
  const [showDeleteSvaAlert, setshowDeleteSvaAlert] = useState(false);

  const [sva, setSva] = useState(props.sva);

  const [urnList, setUrnList] = useState([]);

  const [errorAlert, setErrorAlert] = useState(null);
  const [successAlert, setSuccessAlert] = useState(null);

  const inputFile = useRef(null);

  function compareIncTable(a, b) {
    if (a.userbase > b.userbase) return 1;
    if (b.userbase > a.userbase) return -1;
    return 0;
  }

  // parse da planilha usada para fazer o upload da tabela incremental
  const parseSheet = async function (aData) {
    const workBook = XLSX.read(aData, {
      type: 'buffer',
    });
    const worksheetNames = workBook.SheetNames;

    let blankCounter = 0;
    const layout = {
      colunaUser: 'B',
      colunaPrice: 'C',
      colunaDiscount: 'D',
      linhaInicial: 3,
    };
    let currentLine = layout.linhaInicial - 1;

    // pega a primeira aba da planilha
    if (worksheetNames.length === 0) {
      return;
    }
    const wName = worksheetNames[0];
    const workSheet = workBook.Sheets[wName];

    let mySva = Object.assign({}, sva);
    mySva.incTable = [];

    // the exit criteria is 20 blank lines (channel column) in sequence
    while (blankCounter < 20) {
      currentLine++;

      const userCell = layout.colunaUser + currentLine.toString();
      const PriceCell = layout.colunaPrice + currentLine.toString();
      const DiscountCell = layout.colunaDiscount + currentLine.toString();

      if (!(userCell in workSheet)) {
        blankCounter++;
        continue;
      }

      blankCounter = 0;

      const user = workSheet[userCell].v;
      const price = workSheet[PriceCell].v;
      let discount = null;
      if (typeof workSheet[DiscountCell] != 'undefined') {
        discount = workSheet[DiscountCell].v;
      }

      mySva.incTable.push({
        userbase: user,
        value: price,
        discount: discount,
      });
      mySva.incTable = [...mySva.incTable.sort(compareIncTable)];
    }
    mySva.isIncTable = true;

    setSva(mySva);
  };

  async function onDeleteSva() {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      const result = await API.graphql(
        graphqlOperation(mutations.deleteSVA, {
          input: {
            id: sva.id,
          },
        })
      );

      dispatch({ type: actions.REMOVE_SVA, payload: sva });
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });
      setshowDeleteSvaAlert(false);
    }
  }

  async function onDeleteIncTable(index) {
    // TODO verificar se não tem outro com o mesmo nome antes
    // e aí salvar o canal (ou criar, se não existir)
    let c = Object.assign({}, sva);
    c.incTable.splice(index, 1);
    setSva(c);
  }

  const uploadSheet = async () => {
    // `current` points to the mounted file input element
    inputFile.current.value = null;
    inputFile.current.onchange = () => {
      try {
        dispatch({
          type: actions.LOADING,
          payload: true,
        });
        const sheetFile = inputFile.current.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
          await parseSheet(e.target.result);
        };
        reader.readAsArrayBuffer(sheetFile);
      } catch (error) {
        console.log(error);
        setErrorAlert(error.toString());
      } finally {
        dispatch({
          type: actions.LOADING,
          payload: false,
        });
      }
    };

    inputFile.current.click();
    //}
  };

  // busca em uma URL do sistema do GATEWAY as URNs disponíveis
  const updateUrnList = async function () {
    fetch(
      'https://20arn6e6dj.execute-api.us-east-1.amazonaws.com/production/listaurns'
    )
      .then((res) => res.json())
      .then((data) => {
        setUrnList(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    updateUrnList();
  }, []);

  function plain(aStr) {
    return aStr
      .toString()
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  const listSvas = async function () {
    try {
      setLoading(true);
      const response = await API.graphql(
        graphqlOperation(queries.listSVAs, { limit: 200 })
      );

      return response.data.listSVAs.items;
    } catch (error) {
      setErrorAlert(error.toString());
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const save = async () => {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      const svaList = await listSvas();
      for (const item of svaList) {
        if (plain(item.name) === plain(sva.name)) {
          setErrorAlert('Há outro SVA com o mesmo nome');
          return;
        }
      }

      setLoading(true);
      const svaInput = {
        broadcasterId: sva.broadcasterId,
        id: sva.id,
        name: sva.name,
        incTable: sva.incTable,
        isIncTable: Boolean(sva.isIncTable),
        disabled: Boolean(sva.disabled),
        disabledDate: sva.disabledDate || '',
        urn: sva.urn || '',
      };

      const result = await API.graphql(
        graphqlOperation(mutations.updateSVA, { input: svaInput })
      );

      setSuccessAlert('SVA Salvo');
    } catch (error) {
      console.log(error);
      let msg = error.toString();
      if ('errors' in error) {
        msg = error.errors[0].message;
      }
      setErrorAlert(msg);
    } finally {
      setLoading(false);
    }
  };

  const onChangeName = function (aName) {
    let c = Object.assign({}, sva);
    c.name = aName;
    setSva(c);
  };

  function handleToggleIsIncremental(aEvent) {
    let mySva = Object.assign({}, sva);
    mySva.isIncTable = Boolean(aEvent.target.checked);
    setSva(mySva);
  }

  function handleToggleDisabled(aEvent) {
    const dt = new Date();
    let mySva = Object.assign({}, sva);
    mySva.disabled = Boolean(aEvent.target.checked);
    mySva.disabledDate = dt.toISOString();
    setSva(mySva);
  }

  function handleChangeUrn(event) {
    const newUrn = event.target.value;
    let mySva = Object.assign({}, sva);
    mySva.urn = newUrn;
    setSva(mySva);
  }

  function handleCancel() {
    setEditing(false);
  }

  return (
    <Fragment>
      <Dialog
        open={showDeleteSvaAlert}
        onClose={() => setshowDeleteSvaAlert(false)}
      >
        <DialogTitle id="alert-dialog-title">{'Remover SVA'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Quer realmente remover o SVA?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setshowDeleteSvaAlert(false)} autoFocus>
            Cancelar
          </Button>
          <Button onClick={onDeleteSva}>Sim</Button>
        </DialogActions>
      </Dialog>

      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        accept=".xls,.xlsx"
      />
      <Box sx={styles.svaBox}>
        <FormControl sx={styles.nameField}>
          <Accordion className="col-12">
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Box sx={styles.summarySva}>
                <FormControl>
                  <TextField
                    autoFocus
                    value={sva.name}
                    onChange={(event) => onChangeName(event.target.value)}
                    required
                    type="text"
                    variant="standard"
                  />
                  <FormHelperText>SVA</FormHelperText>
                </FormControl>
                <FormControl>
                  <Select
                    name="urn"
                    value={sva.urn}
                    onChange={(e) => handleChangeUrn(e)}
                    variant="standard"
                    sx={styles.select}
                  >
                    <MenuItem key={0} value={''}>
                      N/A
                    </MenuItem>
                    {urnList.sort().map((item, idx) => (
                      <MenuItem key={item.urn} value={item.urn}>
                        {item.urn}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>URN</FormHelperText>
                </FormControl>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onClick={(event) => handleToggleIsIncremental(event)}
                        checked={Boolean(sva.isIncTable)}
                      />
                    }
                    label="Incremental"
                  />

                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onClick={(event) => handleToggleDisabled(event)}
                        checked={Boolean(sva.disabled)}
                      />
                    }
                    label="Desabilitar"
                  />
                </FormGroup>

                <Tooltip title="Salvar">
                  <Box>
                    <IconButton
                      size="small"
                      onClick={() => save()}
                      edge="end"
                      sx={{
                        color: 'white',
                        backgroundColor: green[500],
                        marginLeft: 'auto',
                        height: 'fit-content',
                      }}
                    >
                      <Save />
                    </IconButton>
                  </Box>
                </Tooltip>

                <Tooltip title="Remover Serviço Digital">
                  <span>
                    <IconButton
                      className="jr-btn jr-btn-xs text-red"
                      onClick={() => setshowDeleteSvaAlert(true)}
                      sx={{
                        color: 'white',
                        backgroundColor: red[500],
                        marginLeft: 'auto',
                        height: 'fit-content',
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </AccordionSummary>

            <AccordionDetails>
              <Fragment>
                <Box sx={styles.buttons}>
                  <Tooltip title="Fazer upload de planilha">
                    <Button
                      onClick={(e) => uploadSheet(e)}
                      variant="contained"
                      type="submit"
                      color="primary"
                    >
                      <Backup />
                    </Button>
                  </Tooltip>
                </Box>

                <Box sx={styles.flexStyle}>
                  {sva.incTable &&
                    sva.incTable.map((item, idx) => (
                      <IncTableItem
                        onDelete={onDeleteIncTable}
                        userbase={item.userbase}
                        discount={item.discount}
                        value={item.value}
                        index={idx}
                        key={idx}
                      />
                    ))}
                </Box>
              </Fragment>
            </AccordionDetails>
          </Accordion>
        </FormControl>
      </Box>

      {/* Mensagens de sucesso  */}
      <Snackbar
        open={Boolean(successAlert)}
        autoHideDuration={1500}
        onClose={() => setSuccessAlert(null)}
      >
        <Alert
          onClose={() => setSuccessAlert(null)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successAlert}
        </Alert>
      </Snackbar>

      {/* Mensagens de erro  */}
      <Snackbar
        open={Boolean(errorAlert)}
        autoHideDuration={1500}
        onClose={() => setErrorAlert(null)}
      >
        <Alert
          onClose={() => setErrorAlert(null)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorAlert}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default SvaItem;
