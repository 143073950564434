import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
  useRef,
} from 'react';

import { Navigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import validaCNPJ from 'util/CnpjCheck';
import * as mutations from 'graphql/mutations';
import * as customGql from 'graphql/custom/functions';
import { API, graphqlOperation } from 'aws-amplify';
import * as customQueries from 'graphql/custom/queries';

import {
  TextField,
  InputAdornment,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  FormControl,
  FormGroup,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography,
  Box,
  Fab,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Snackbar,
  Alert,
} from '@mui/material';

import {
  Backup,
  Save,
  Delete,
  ExpandMore,
  Visibility,
} from '@mui/icons-material';

import { v4 } from 'uuid';
import Loading from 'util/loading';

import ChannelItem from './channel';
import SvaItem from './sva';
import VodItem from './vod';
import HboMaxMgMetaTable from './hboMaxMgMetaTable';
import FlexiblePriceTable from './flexiblePriceTable';
import ExternalUsers from '../../../../components/externalUsers';

import BroadcasterContext from './context';
import * as actions from './actions';

import { red, indigo, green, grey, blue } from '@mui/material/colors';
import * as XLSX from 'xlsx';
import { minWidth } from '@mui/system';

const styles = {
  select: {
    height: '100%',
    marginBottom: '8px',
  },
  addChannelButton: {
    marginBottom: '16px',
  },
  buttons: {
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputs: {
    paddingRight: 1,
    minWidth: '140px',
  },
  fix: {
    marginTop: '0px',
  },
  header: {
    padding: 3,
    display: 'flex',
    justifyContent: 'space-between',
  },
  formgroup: {
    padding: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  dadosCadastrais: {
    padding: 2,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
};

const BroadcasterItem = ({ myBroadcasterId }) => {
  const { state, dispatch } = useContext(BroadcasterContext);
  const [loading, setLoading] = useState(false);
  const [newitem, setNewItem] = useState(true);
  const [layouts, setLayouts] = useState([]);
  const [layoutsSva, setLayoutsSva] = useState([]);
  const [layoutsVod, setLayoutsVod] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [newSvaName, setNewSvaName] = useState('');
  const [showDeleteBroadcasterAlert, setShowDeleteBroadcasterAlert] =
    useState(false);

  const [errorAlert, setErrorAlert] = useState(null);
  const [successAlert, setSuccessAlert] = useState(null);

  const [openSva, setOpenSva] = useState(false);
  const [openHboMaxMgMetaTable, setOpenHboMaxMgMetaTable] = useState(false);

  /**
   * Lista os ENUMs que tem nos arquivos de
   * definição das tabelas do graphql (os .schema)
   */
  const listLayouts = async function () {
    try {
      setLoading(true);
      const result = await API.graphql(
        graphqlOperation(customQueries.listEnum, {
          enumType: 'Layout',
        })
      );
      const t = [];
      for (const e of result.data.__type.enumValues) {
        t.push(e.name);
      }
      setLayouts(t.sort());
    } catch (error) {
      setErrorAlert(error.toString());
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const salva = async () => {
    try {
      setLoading(true);

      // TODO verificar se não tem outro com o mesmo nome antes
      // e aí salvar o canal (ou criar, se não existir)

      const broadcasterInput = {
        company_name: state.broadcaster.company_name,
        corporate_registry: state.broadcaster.corporate_registry,
        trade: state.broadcaster.trade,
        id: state.broadcaster.id,
        flexibleTables: state.broadcaster.flexibleTables,
        flexiblePriceTableB2B: state.broadcaster.flexiblePriceTableB2B || '',
        disabled: Boolean(state.broadcaster.disabled),
        disabledDate: state.broadcaster.disabledDate || '',
        layout: state.broadcaster.layout,
        layoutSva: state.broadcaster.layoutSva,
        layoutVod: state.broadcaster.layoutVod,
        hboMaxMgMetaTable: state.broadcaster.hboMaxMgMetaTable || [],
      };

      const result = await API.graphql(
        graphqlOperation(mutations.updateBroadcaster, {
          input: broadcasterInput,
        })
      );
      setSuccessAlert('Dados salvos com sucesso');
    } catch (error) {
      console.log(error);
      let msg = error.toString();
      if ('errors' in error) {
        msg = error.errors[0].message;
      }
      setErrorAlert(msg);
    } finally {
      setLoading(false);
    }
  };

  const listLayoutsSva = async function () {
    try {
      setLoading(true);

      const result = await API.graphql(
        graphqlOperation(customQueries.listEnum, {
          enumType: 'LayoutSva',
        })
      );
      const t = [];
      for (const e of result.data.__type.enumValues) {
        t.push(e.name);
      }
      setLayoutsSva(t.sort());
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const listLayoutsVod = async function () {
    try {
      setLoading(true);

      const result = await API.graphql(
        graphqlOperation(customQueries.listEnum, {
          enumType: 'LayoutVod',
        })
      );
      const t = [];
      for (const e of result.data.__type.enumValues) {
        t.push(e.name);
      }
      setLayoutsVod(t.sort());
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  function compareChannel(a, b) {
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (b.name.toLowerCase() > a.name.toLowerCase()) return -1;
    return 0;
  }

  useEffect(() => {
    listLayouts();
    listLayoutsSva();
    listLayoutsVod();
  }, []);

  const parseHboMaxMgMetaSheet = async function (aData) {
    try {
      const workBook = XLSX.read(aData, {
        type: 'buffer',
      });
      const worksheetNames = workBook.SheetNames;
      const wName = worksheetNames[0];
      const xlsjson = XLSX.utils.sheet_to_json(workBook.Sheets[wName]);

      // verifica se a tabela está ok

      // verifica se a tabela está vazia
      if (xlsjson.length === 0) {
        setErrorAlert('Tabela vazia');
        return;
      }

      // converte nomes dos campos para lowercase e garante apenas os campos necessários
      const normalizedData = xlsjson.map((record) => {
        // Extrair apenas os campos necessários: PORTE, BASE, MG
        const requiredKeys = ['porte', 'base', 'mg'];
        const newRecord = {};

        // Processar apenas os campos necessários, ignorando extras
        Object.keys(record).forEach((key) => {
          const lowerKey = key.toLowerCase();
          if (requiredKeys.includes(lowerKey)) {
            newRecord[lowerKey] = record[key];
          }
        });

        return newRecord;
      });

      // Check if the normalized data has the required fields
      for (const record of normalizedData) {
        if (!('porte' in record && 'base' in record && 'mg' in record)) {
          // Find which required fields are missing
          const missingFields = [];
          if (!('porte' in record)) missingFields.push('PORTE');
          if (!('base' in record)) missingFields.push('BASE');
          if (!('mg' in record)) missingFields.push('MG');

          setErrorAlert(`Missing field(s): ${missingFields.join(', ')}`);
          return;
        }
      }

      // Check if array is sorted by BASE field
      const isSorted = normalizedData.every((item, i, arr) => {
        if (i === 0) return true;
        return parseFloat(arr[i - 1].base) <= parseFloat(item.base);
      });

      if (!isSorted) {
        setErrorAlert(
          'The table must be sorted by the BASE field in ascending order'
        );
        return;
      }

      const updatedBroadcaster = { ...state.broadcaster };
      updatedBroadcaster.hboMaxMgMetaTable = normalizedData;
      dispatch({ type: actions.SET_BROADCASTER, payload: updatedBroadcaster });
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    }
  };

  const inputFile = useRef(null);

  const uploadHboMaxMgMetaSheet = async () => {
    // `current` points to the mounted file input element
    inputFile.current.value = null;
    inputFile.current.onchange = () => {
      try {
        setLoading(true);
        const sheetFile = inputFile.current.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
          await parseHboMaxMgMetaSheet(e.target.result);
        };
        reader.readAsArrayBuffer(sheetFile);
      } catch (error) {
        console.log(error);
        setErrorAlert(error.toString());
      } finally {
        setLoading(false);
      }
    };

    inputFile.current.click();
  };

  function handleChange(event) {
    const f = event.target.name;
    const v = event.target.value;
    let b = Object.assign({}, state.broadcaster);
    b[f] = v;

    dispatch({ type: actions.SET_BROADCASTER, payload: b });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (validaCNPJ(state.broadcaster.corporate_registry) === false) {
      setErrorAlert('CNPJ inválido');

      return;
    }

    if (state.broadcaster.increasePercentage === '') {
      setErrorAlert('% contratual inválido');
      return;
    }

    // // se estiver desabilitando
    // if (state.broadcaster.disabled) {
    //   let channelsEnabled = false;
    //   for (const channel of state.broadcaster.channels.items) {
    //     if (!Boolean(channel.disabled)) {
    //       channelsEnabled = true;
    //     }
    //   }
    //   if (channelsEnabled) {
    //     NotificationManager.warning(
    //       'Desabilite todos os canais antes de desabilitar o programador',
    //       null,
    //       4000
    //     );
    //     let b = Object.assign({}, state.broadcaster);
    //     b.disabled = false;
    //     dispatch({ type: actions.SET_BROADCASTER, payload: b });
    //     return;
    //   }
    // }

    let operation;
    const broadcasterInput = {
      company_name: state.broadcaster.company_name,
      corporate_registry: state.broadcaster.corporate_registry,
      trade: state.broadcaster.trade,
      id: state.broadcaster.id,
      disabled: Boolean(state.broadcaster.disabled),
      layout: state.broadcaster.layout,
      layoutSva: state.broadcaster.layoutSva,
      layoutVod: state.broadcaster.layoutVod,
      increasePercentage: state.broadcaster.increasePercentage,
    };

    if (newitem) {
      operation = mutations.createBroadcaster;
    } else {
      operation = mutations.updateBroadcaster;
    }
    try {
      setLoading(true);

      const result = await API.graphql(
        graphqlOperation(operation, {
          input: broadcasterInput,
        })
      );

      if (result.errors) {
        throw new Error(JSON.stringify(result.errors));
      } else {
        setSuccessAlert('Dados salvos com sucesso');
        setNewItem(false);
      }
    } catch (error) {
      console.log(error);
      const msg = error.message || error.errors[0] || error;
      setErrorAlert(msg);
    } finally {
      setLoading(false);
    }
  }

  async function onDelete() {
    try {
      setLoading(true);

      const result = await API.graphql(
        graphqlOperation(mutations.deleteBroadcaster, {
          input: {
            id: state.broadcaster.id,
          },
        })
      );

      setSuccessAlert('Dados removidos com sucesso');
    } catch (error) {
      console.log(error);
      let msg = 'message' in error ? error.message : error;
      setErrorAlert(msg);
    } finally {
      setLoading(false);
      setShowDeleteBroadcasterAlert(false);
      setRedirect(true);
    }
  }

  async function getBroadcaster(aId) {
    try {
      setLoading(true);

      const b = await customGql.getBroadcaster(aId);
      dispatch({ type: actions.SET_BROADCASTER, payload: b });
    } catch (error) {
      console.log(error);
      const msg = error.message || error.toString();
      setErrorAlert(msg);
    } finally {
      setLoading(false);
    }
  }

  function handleToggleEnabled(aEvent) {
    let b = Object.assign({}, state.broadcaster);
    b.disabled = !aEvent.target.checked;
    const dt = new Date();
    b.disabledDate = dt.toISOString();
    dispatch({ type: actions.SET_BROADCASTER, payload: b });
  }

  useEffect(() => {
    if (Boolean(myBroadcasterId) === false) {
      setRedirect(true);
      return;
    }

    if (myBroadcasterId !== '000') {
      setNewItem(false);
      getBroadcaster(myBroadcasterId);
    }
  }, [myBroadcasterId]);

  const addChannel = function () {
    const b = {
      id: v4(),
      name: '',
      disabled: false,
      broadcaster: {
        id: state.broadcaster.id,
      },
    };

    dispatch({ type: actions.ADD_CHANNEL, payload: b });
  };

  const addSva = async function () {
    const sva = {
      id: v4(),
      name: newSvaName,
      broadcasterId: state.broadcaster.id,
      disabled: false,
      disabledDate: '',
      isIncTable: false,
      incTable: [],
      urn: '',
    };

    dispatch({ type: actions.ADD_SVA, payload: sva });

    try {
      setLoading(true);

      // TODO verificar se não tem outro com o mesmo nome antes

      const svaInput = {
        broadcasterId: sva.broadcasterId,
        id: sva.id,
        name: sva.name,
        disabled: sva.disabled,
        disabledDate: sva.disabledDate,
        isIncTable: false,
      };

      const result = await API.graphql(
        graphqlOperation(mutations.createSVA, { input: svaInput })
      );
    } catch (error) {
      console.log(error);
      let msg = error.toString();
      if ('errors' in error) {
        msg = error.errors[0].message;
      }
      setErrorAlert(msg);
    } finally {
      setLoading(false);
      setOpenSva(false);
    }
  };

  const handleAddVod = async function () {
    const newVod = {
      id: v4(),
      name: '',
      broadcasterId: state.broadcaster.id,
      disabled: false,
      disabledDate: '',
    };

    dispatch({ type: actions.ADD_VOD, payload: newVod });
  };

  const onChangeSvaName = function (aName) {
    setNewSvaName(aName);
  };

  return (
    <Box>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        accept=".xls,.xlsx"
      />
      {redirect && <Navigate to="/" replace state={state} />}

      <Loading loading={loading} />

      {/* Alerta para remover parceiro */}
      <Dialog
        open={showDeleteBroadcasterAlert}
        onClose={() => setShowDeleteBroadcasterAlert(false)}
      >
        <DialogTitle id="alert-dialog-title">
          {'Remover programador'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Quer realmente remover o programador?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowDeleteBroadcasterAlert(false)}
            autoFocus
          >
            Cancelar
          </Button>
          <Button onClick={onDelete}>Sim</Button>
        </DialogActions>
      </Dialog>

      <Box sx={styles.header}>
        <Typography variant="h6">
          {newitem ? 'Novo programador' : state.broadcaster.trade}
        </Typography>

        <Box display={newitem ? 'none' : 'block'}>
          <Typography variant="subtitle1" gutterBottom>
            {state.broadcaster.channels.items.length} &nbsp;
            {state.broadcaster.channels.items.length === 1 ? 'canal' : 'canais'}
          </Typography>
        </Box>

        <Box display={newitem ? 'none' : 'block'}>
          <Tooltip title={'Remover programador'}>
            <Fab
              size="small"
              sx={{
                color: 'white',
                backgroundColor: red[500],
                marginLeft: 'auto',
              }}
              onClick={() => setShowDeleteBroadcasterAlert(true)}
            >
              <Delete />
            </Fab>
          </Tooltip>
        </Box>
      </Box>

      {/* Dados cadastrais */}
      <Accordion slotProps={{ transition: { mountOnEnter: true } }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>{'Dados cadastrais'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <form id="broadcasterData" autoComplete="off" onSubmit={handleSubmit}>
            <Box sx={styles.dadosCadastrais}>
              <FormGroup sx={styles.formgroup}>
                <FormControl
                  variant="standard"
                  sx={[styles.inputs, { minWidth: '360px' }]}
                >
                  <TextField
                    name="company_name"
                    value={state.broadcaster.company_name}
                    onChange={handleChange}
                    required
                    variant="standard"
                  />
                  <FormHelperText>Razão Social</FormHelperText>
                </FormControl>

                <FormControl variant="standard" sx={styles.inputs}>
                  <TextField
                    name="trade"
                    value={state.broadcaster.trade}
                    onChange={handleChange}
                    required
                    variant="standard"
                  />
                  <FormHelperText>Marca</FormHelperText>
                </FormControl>

                <FormControl variant="standard" sx={styles.inputs}>
                  <InputMask
                    mask="99.999.999/9999-99"
                    value={state.broadcaster.corporate_registry}
                    onChange={handleChange}
                  >
                    {() => (
                      <TextField
                        name="corporate_registry"
                        margin="normal"
                        required
                        variant="standard"
                        sx={styles.fix}
                      />
                    )}
                  </InputMask>
                  <FormHelperText>CNPJ</FormHelperText>
                </FormControl>
              </FormGroup>

              <FormGroup sx={styles.formgroup}>
                <FormControl variant="standard" sx={styles.inputs}>
                  <Select
                    name="layout"
                    value={state.broadcaster.layout}
                    onChange={handleChange}
                    variant="standard"
                    sx={styles.select}
                  >
                    {layouts.map((item, idx) => (
                      <MenuItem key={idx} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Layout do relatório de tv</FormHelperText>
                </FormControl>

                <FormControl variant="standard" sx={styles.inputs}>
                  <Select
                    name="layoutSva"
                    value={state.broadcaster.layoutSva}
                    onChange={handleChange}
                    variant="standard"
                    sx={styles.select}
                  >
                    {layoutsSva.map((item, idx) => (
                      <MenuItem key={idx} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Layout do relatório de sva</FormHelperText>
                </FormControl>

                <FormControl variant="standard" sx={styles.inputs}>
                  <Select
                    name="layoutVod"
                    value={state.broadcaster.layoutVod}
                    onChange={handleChange}
                    variant="standard"
                    sx={styles.select}
                  >
                    {layoutsVod.map((item, idx) => (
                      <MenuItem key={idx} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Layout do relatório de VOD</FormHelperText>
                </FormControl>

                <FormControl sx={styles.inputs}>
                  <TextField
                    name="increasePercentage"
                    value={state.broadcaster.increasePercentage || '0'}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    type="number"
                    variant="standard"
                  />
                  <FormHelperText>% contratual por atraso</FormHelperText>
                </FormControl>
              </FormGroup>

              <FormGroup
                sx={[styles.formgroup, { justifyContent: 'flex-end' }]}
              >
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      onClick={(event) => handleToggleEnabled(event)}
                      checked={!Boolean(state.broadcaster.disabled)}
                    />
                  }
                  label="Habilitado"
                />

                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  startIcon={<Backup />}
                >
                  Salvar
                </Button>
              </FormGroup>
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>

      {/* Canais */}
      <Accordion
        disabled={newitem}
        slotProps={{ transition: { mountOnEnter: true } }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>{'Canais'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Fragment>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={addChannel}
              sx={styles.addChannelButton}
            >
              <span>Acrescentar canal</span>
            </Button>
            {state.broadcaster.channels.items
              .sort(compareChannel)
              .map((item, idx) => (
                <ChannelItem channel={item} key={item.id} />
              ))}
          </Fragment>
        </AccordionDetails>
      </Accordion>

      {/* HBO MAX custom data */}
      {state.broadcaster.layoutSva === 'HBOMAX' && (
        <Accordion
          disabled={newitem}
          slotProps={{ transition: { mountOnEnter: true } }}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Tabela MG/Meta</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Fragment>
              <Box display={newitem ? 'none' : 'block'}>
                <Tooltip title="Upload da tabela com as colunas PORTE, BASE e MG">
                  <IconButton
                    onClick={(e) => uploadHboMaxMgMetaSheet(e)}
                    edge="end"
                    sx={{
                      color: 'white',
                      backgroundColor: blue[500],
                      marginLeft: 'auto',
                      height: 'fit-content',
                      marginRight: 4,
                    }}
                  >
                    <Backup />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Exibir tabela">
                  <IconButton
                    onClick={(e) => setOpenHboMaxMgMetaTable(true)}
                    edge="end"
                    sx={{
                      color: 'white',
                      backgroundColor: red[500],
                      marginLeft: 'auto',
                      height: 'fit-content',
                      marginRight: 4,
                    }}
                  >
                    <Visibility />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Salvar">
                  <IconButton
                    onClick={salva}
                    edge="end"
                    sx={{
                      color: 'white',
                      backgroundColor: green[500],
                      marginLeft: 'auto',
                      height: 'fit-content',
                    }}
                  >
                    <Save />
                  </IconButton>
                </Tooltip>

                <HboMaxMgMetaTable
                  show={openHboMaxMgMetaTable}
                  setShow={setOpenHboMaxMgMetaTable}
                  hboTable={state.broadcaster.hboMaxMgMetaTable}
                />
              </Box>
            </Fragment>
          </AccordionDetails>
        </Accordion>
      )}

      {/* Serviços digitais */}
      <Accordion
        disabled={newitem}
        slotProps={{ transition: { mountOnEnter: true } }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Serviços Digitais</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Fragment>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={() => setOpenSva(true)}
              sx={styles.addChannelButton}
            >
              <span>Acrescentar serviço digital</span>
            </Button>
            <Dialog open={openSva} onClose={() => setOpenSva(false)}>
              <DialogTitle>Serviço Digital</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Serviço Digital"
                  value={newSvaName}
                  onChange={(event) => onChangeSvaName(event.target.value)}
                  required
                  type="text"
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenSva(false)}>Cancelar</Button>
                <Button onClick={addSva}>Inscrever</Button>
              </DialogActions>
            </Dialog>

            {state.broadcaster.svas.items.map((item, idx) => (
              <SvaItem sva={item} key={item.id} />
            ))}
          </Fragment>
        </AccordionDetails>
      </Accordion>

      {/* tabela de preços flexiveis B2C */}
      <Accordion
        disabled={newitem}
        slotProps={{ transition: { mountOnEnter: true } }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Tabela de preços flexíveis B2C</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FlexiblePriceTable
            isB2B={false}
            priceTable={state.broadcaster.flexibleTables}
            save={salva}
          />
        </AccordionDetails>
      </Accordion>

      {/* tabela de preços flexiveis B2B */}
      <Accordion
        disabled={newitem}
        slotProps={{ transition: { mountOnEnter: true } }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Tabela de preços flexíveis B2B</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FlexiblePriceTable
            isB2B={true}
            priceTable={state.broadcaster.flexiblePriceTableB2B}
            save={salva}
          />
        </AccordionDetails>
      </Accordion>

      {/* VOD */}
      <Accordion
        disabled={newitem}
        slotProps={{ transition: { mountOnEnter: true } }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>VOD</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Fragment>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={handleAddVod}
              sx={styles.addChannelButton}
            >
              <span>Acrescentar VOD</span>
            </Button>

            {state.broadcaster.vod &&
              state.broadcaster.vod.items.map((item, idx) => (
                <VodItem vod={item} key={item.id} />
              ))}
          </Fragment>
        </AccordionDetails>
      </Accordion>

      {/* Usuários externos */}
      <Accordion
        disabled={newitem}
        slotProps={{ transition: { mountOnEnter: true } }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Usuários externos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ExternalUsers companyId={state.broadcaster.id} />
        </AccordionDetails>
      </Accordion>

      {/* Mensagens de sucesso  */}
      <Snackbar
        open={Boolean(successAlert)}
        autoHideDuration={1500}
        onClose={() => setSuccessAlert(null)}
      >
        <Alert
          onClose={() => setSuccessAlert(null)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successAlert}
        </Alert>
      </Snackbar>

      {/* Mensagens de erro  */}
      <Snackbar
        open={Boolean(errorAlert)}
        autoHideDuration={2500}
        onClose={() => setErrorAlert(null)}
      >
        <Alert
          onClose={() => setErrorAlert(null)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorAlert}
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default BroadcasterItem;
