import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import OperatorContext from './context';
import {
  Alert,
  Snackbar,
  FormGroup,
  FormHelperText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TextField,
  IconButton,
  Button,
  Tooltip,
  FormControlLabel,
  Switch,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Divider,
  CardContent,
  Card,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Backup, Delete, Save, Add, ExpandMore } from '@mui/icons-material';
import { red, indigo, green, grey, blue } from '@mui/material/colors';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import * as actions from './actions';
import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';

const XLSX = require('xlsx');
const styles = {
  mainPanel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 1,
    marginBottom: 2,
    padding: 1,
  },
  containerLine: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '10px 10px',
    marginTop: 1,
    marginBottom: 1,
  },
  delete: {
    justifySelf: 'flex-end',
  },
  panel: {
    padding: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: 2,
    gap: 2,
  },
  accordion: {
    margin: 1,
  },
};

const ServicoDigital = ({ id, onDelete, sva }) => {
  const [availableSvas, setAvailableSvas] = useState([]);
  const { state, dispatch } = useContext(OperatorContext);
  const [mySva, setMySva] = useState(sva);
  const inputFile = useRef(null);

  const [errorAlert, setErrorAlert] = useState(null);
  const [successAlert, setSuccessAlert] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [accordionExpanded, setAccordionExpanded] = useState(false);

  const layout = {
    colunaMes: 'A',
    colunaPC: 'B',
    linhaInicial: 2,
  };

  useEffect(() => {
    listSvas();
  }, []);

  function handleToggleCombo(aEvent) {
    let localCopy = Object.assign({}, mySva);
    localCopy.combo = aEvent.target.checked;
    setMySva(localCopy);
  }

  function handleToggleDisabled(aEvent) {
    let localCopy = Object.assign({}, mySva);
    localCopy.disabled = aEvent.target.checked;
    const dt = new Date();
    localCopy.disabledDate = dt.toISOString();
    setMySva(localCopy);
  }

  function handleToggleIsB2B(aEvent) {
    let localCopy = Object.assign({}, mySva);
    localCopy.isB2B = aEvent.target.checked;
    setMySva(localCopy);
  }

  function handleChangeClient(aEvent) {
    let localCopy = Object.assign({}, mySva);
    localCopy.clientNumber = aEvent.target.value;
    setMySva(localCopy);
  }

  function handleChangeContract(aEvent) {
    let localCopy = Object.assign({}, mySva);
    localCopy.contractNumber = aEvent.target.value;
    setMySva(localCopy);
  }

  function handleChangeStartDate(aDate) {
    let localCopy = Object.assign({}, mySva);
    localCopy.startDate = aDate.toISOString();
    setMySva(localCopy);
  }

  const listSvas = async function () {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      const b = await API.graphql(
        graphqlOperation(queries.listSVAs, { limit: 200 })
      );
      setAvailableSvas(b.data.listSVAs.items);
    } catch (error) {
      setErrorAlert(error.toString());
      console.log(error);
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });
    }
  };

  const parseSheet = async function (aData) {
    let currentLine = layout.linhaInicial - 1;
    let blankCounter = 0;

    const workBook = XLSX.read(aData, {
      type: 'buffer',
    });
    const worksheetNames = workBook.SheetNames;
    // the exit criteria is 20 blank lines (channel column) in sequence

    // faz o parse das planilhas
    for (const wName of worksheetNames) {
      const workSheet = workBook.Sheets[wName];
      while (blankCounter < 20) {
        currentLine++;

        const mesCell = layout.colunaMes + currentLine.toString();
        const PCCell = layout.colunaPC + currentLine.toString();

        if (!(mesCell in workSheet)) {
          blankCounter++;
          continue;
        }

        blankCounter = 0;

        const serial = workSheet[mesCell].v;
        const pc = workSheet[PCCell].v;
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400;
        const date_info = new Date(utc_value * 1000);

        const month = date_info.getUTCMonth() + 1; // do 1 ao 12
        const year = date_info.getUTCFullYear();
        addLine(pc, month, year);
      }
    }
  };

  const uploadSheet = async () => {
    // `current` points to the mounted file input element
    inputFile.current.value = null;
    inputFile.current.onchange = () => {
      try {
        dispatch({
          type: actions.LOADING,
          payload: true,
        });
        const sheetFile = inputFile.current.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
          await parseSheet(e.target.result);

          //loadSpreadsheets();
        };
        reader.readAsArrayBuffer(sheetFile);
      } catch (error) {
        console.log(error);
        setErrorAlert(error.toString());
      } finally {
        dispatch({
          type: actions.LOADING,
          payload: false,
        });
      }
    };

    inputFile.current.click();
  };

  const handleChangeMonth = (event, index) => {
    const localCopy = Object.assign({}, mySva);
    localCopy.planTable[index].month = parseInt(event.target.value);
    setMySva(localCopy);
  };
  const handleChangeYear = (event, index) => {
    const localCopy = Object.assign({}, mySva);
    localCopy.planTable[index].year = parseInt(event.target.value);
    setMySva(localCopy);
  };
  const handleChangeUserBase = (event, index) => {
    const localCopy = Object.assign({}, mySva);
    localCopy.planTable[index].userbase = parseInt(event.target.value);
    setMySva(localCopy);
  };
  const handleChangeSva = (event) => {
    const localCopy = Object.assign({}, mySva);
    localCopy.svaId = event.target.value;
    setMySva(localCopy);
  };
  const onDeleteLine = (index) => {
    let localCopy = Object.assign({}, mySva);
    localCopy.planTable.splice(index, 1);
    setMySva(localCopy);
  };

  const addLine = (aUserBase = 0, aMonth = 0, aYear = 0) => {
    const localCopy = Object.assign({}, mySva);
    localCopy.planTable.push({
      userbase: aUserBase,
      month: aMonth,
      year: aYear,
    });
    setMySva(localCopy);
  };

  function comparePlanTable(a, b) {
    if (a.year > b.year) return 1;
    if (b.year > a.year) return -1;
    if (a.month > b.month) return 1;
    if (b.month > a.month) return -1;

    return 0;
  }

  const salva = async () => {
    try {
      dispatch({
        type: actions.LOADING,
        payload: true,
      });

      // TODO verificar se não tem outro com o mesmo nome antes

      const growthPlansInput = {
        combo: Boolean(mySva.combo),
        disabled: Boolean(mySva.disabled),
        disabledDate: mySva.disabledDate || '',
        svaId: mySva.svaId,
        id: id,
        growthPlanSvaId: mySva.svaId,
        growthPlanOperatorId: state.operator.id,
        operatorId: state.operator.id,
        planTable: mySva.planTable.sort(comparePlanTable),
        clientNumber: mySva.clientNumber || '',
        contractNumber: mySva.contractNumber || '',
        isB2B: Boolean(mySva.isB2B),
        startDate: mySva.startDate || '',
      };

      let operation;
      if (!('createdAt' in mySva)) {
        operation = mutations.createGrowthPlan;
      } else {
        operation = mutations.updateGrowthPlan;
      }

      const response = await API.graphql(
        graphqlOperation(operation, {
          input: growthPlansInput,
        })
      );

      function extractGrowthPlan(aResponse) {
        if (!aResponse || !aResponse.data) {
          throw new Error('Invalid data format');
        }

        // Extract either createGrowthPlan or updateGrowthPlan
        const key = Object.keys(aResponse.data).find(
          (key) => key === 'createGrowthPlan' || key === 'updateGrowthPlan'
        );

        return key ? aResponse.data[key] : null;
      }

      setMySva(extractGrowthPlan(response));

      setSuccessAlert('Salvo com sucesso');
    } catch (error) {
      console.log(error);
      let msg = error.toString();
      if ('errors' in error) {
        msg = error.errors[0].message;
      }
      setErrorAlert(msg);
    } finally {
      dispatch({
        type: actions.LOADING,
        payload: false,
      });
    }
  };

  function plain(str) {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  function compareOperator(a, b) {
    if (plain(a.name) > plain(b.name)) {
      return 1;
    }

    if (plain(a.name) < plain(b.name)) {
      return -1;
    }

    return 0;
  }

  function pad(aValue) {
    let s = '00' + aValue;
    return s.substring(s.length - 2);
  }

  return (
    <Fragment>
      <Accordion
        expanded={accordionExpanded}
        onChange={(e) => setAccordionExpanded(!accordionExpanded)}
        sx={styles.accordion}
        slotProps={{ transition: { mountOnEnter: true } }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          {mySva.sva.name}
        </AccordionSummary>

        <AccordionDetails>
          <Box sx={styles.mainPanel}>
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
              accept=".xls,.xlsx"
            />
            <Paper sx={styles.panel}>
              <FormControl>
                <InputLabel id="mySva-simple-select-label">SVA</InputLabel>

                <Select
                  labelId="mySva-simple-select-label"
                  id="mySva-simple-select"
                  sx={{
                    width: 180,
                  }}
                  value={mySva.svaId}
                  label="Sva"
                  onChange={(e) => handleChangeSva(e)}
                >
                  <MenuItem value={''} disabled selected>
                    Selecione o SVA
                  </MenuItem>
                  {availableSvas.sort(compareOperator).map((item, idx) => {
                    return (
                      <MenuItem key={idx} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      onClick={(event) => handleToggleCombo(event)}
                      checked={Boolean(mySva.combo)}
                    />
                  }
                  label="combo"
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  className="ml-1"
                  control={
                    <Switch
                      color="primary"
                      onClick={(event) => handleToggleDisabled(event)}
                      checked={Boolean(mySva.disabled)}
                    />
                  }
                  label="desabilitar"
                />
              </FormControl>

              <FormControl>
                <FormControlLabel
                  className="ml-1"
                  control={
                    <Switch
                      color="primary"
                      onClick={(event) => handleToggleIsB2B(event)}
                      checked={Boolean(mySva.isB2B)}
                    />
                  }
                  label="B2B"
                />
              </FormControl>

              <FormControl>
                <TextField
                  name="# cliente"
                  value={mySva.clientNumber}
                  onChange={(e) => handleChangeClient(e)}
                  required
                  variant="standard"
                />
                <FormHelperText># cliente</FormHelperText>
              </FormControl>

              <FormControl>
                <TextField
                  name="# contrato"
                  value={mySva.contractNumber}
                  onChange={(e) => handleChangeContract(e)}
                  required
                  variant="standard"
                />
                <FormHelperText># contrato</FormHelperText>
              </FormControl>

              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <DatePicker
                  label="Lançamento"
                  value={dayjs(mySva.startDate)}
                  emptyLabel="não definido"
                  onChange={(e) => handleChangeStartDate(e)}
                />
              </LocalizationProvider>
            </Paper>

            <Paper sx={[styles.mainPanel, { border: 0 }]}>
              <Paper
                sx={[
                  styles.panel,
                  { justifyContent: 'end', boxShadow: 'none' },
                ]}
              >
                <Typography
                  sx={{ flexGrow: 2, display: 'block' }}
                  variant="caption"
                  gutterBottom
                >
                  Plano de Crescimento
                </Typography>
                <Tooltip title="Acrescentar linha ao plano de crescimento">
                  <Box>
                    <IconButton
                      onClick={addLine}
                      disabled={mySva.svaId.length == 0}
                      sx={{
                        color: 'white',
                        backgroundColor: blue[500],
                        marginLeft: 'auto',
                        height: 'fit-content',
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Box>
                </Tooltip>

                <Tooltip title="Subir tabela">
                  <Box>
                    <IconButton
                      onClick={(e) => uploadSheet()}
                      disabled={mySva.svaId.length == 0}
                      sx={{
                        color: 'white',
                        backgroundColor: blue[500],
                        marginLeft: 'auto',
                        height: 'fit-content',
                      }}
                    >
                      <Backup />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Paper>

              <Paper>
                {mySva.planTable.map((item, index) => {
                  return (
                    <Box sx={styles.containerLine} key={index}>
                      <FormControl>
                        <InputLabel id="month-simple-select-label">
                          Mês
                        </InputLabel>
                        <Select
                          labelId="month-simple-select-label"
                          id="month-simple-select"
                          label="Mês"
                          value={pad(item.month)}
                          onChange={(event) => handleChangeMonth(event, index)}
                        >
                          <MenuItem value={''} disabled selected>
                            selecione mês
                          </MenuItem>
                          <MenuItem value={'01'}>Janeiro</MenuItem>
                          <MenuItem value={'02'}>Fevereiro</MenuItem>
                          <MenuItem value={'03'}>Março</MenuItem>
                          <MenuItem value={'04'}>Abril</MenuItem>
                          <MenuItem value={'05'}>Maio</MenuItem>
                          <MenuItem value={'06'}>Junho</MenuItem>
                          <MenuItem value={'07'}>Julho</MenuItem>
                          <MenuItem value={'08'}>Agosto</MenuItem>
                          <MenuItem value={'09'}>Setembro</MenuItem>
                          <MenuItem value={'10'}>Outubro</MenuItem>
                          <MenuItem value={'11'}>Novembro</MenuItem>
                          <MenuItem value={'12'}>Dezembro</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        label="ano"
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 4);
                        }}
                        type="number"
                        value={item.year}
                        variant="standard"
                        onChange={(event) => handleChangeYear(event, index)}
                        required
                      />
                      <TextField
                        label="assinaturas"
                        type="number"
                        value={item.userbase}
                        variant="standard"
                        onChange={(event) => handleChangeUserBase(event, index)}
                        required
                      />

                      <Tooltip title="Remover linha">
                        <Box>
                          <IconButton
                            onClick={() => onDeleteLine(index)}
                            sx={{
                              color: 'white',
                              backgroundColor: red[500],
                              marginLeft: 'auto',
                              height: 'fit-content',
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    </Box>
                  );
                })}
              </Paper>
            </Paper>
          </Box>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Paper
            sx={[styles.panel, { justifyContent: 'end', boxShadow: 'none' }]}
          >
            <Tooltip title="Salvar">
              <Box>
                <IconButton
                  onClick={salva}
                  edge="end"
                  disabled={mySva.svaId.length == 0}
                  sx={{
                    color: 'white',
                    backgroundColor: green[500],
                    marginLeft: 'auto',
                    height: 'fit-content',
                  }}
                >
                  <Save />
                </IconButton>
              </Box>
            </Tooltip>

            <Tooltip title="Remover Serviço Digital">
              <Box>
                <IconButton
                  onClick={(e) => setOpenDelete(true)}
                  sx={{
                    color: 'white',
                    backgroundColor: red[500],
                    marginLeft: 'auto',
                    height: 'fit-content',
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Tooltip>
          </Paper>
        </AccordionActions>
      </Accordion>

      {/* Mensagens de erro  */}
      <Snackbar
        open={Boolean(errorAlert)}
        autoHideDuration={3500}
        onClose={() => setErrorAlert(null)}
      >
        <Alert
          onClose={() => setErrorAlert(null)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorAlert}
        </Alert>
      </Snackbar>

      {/* Mensagens de sucesso  */}
      <Snackbar
        open={Boolean(successAlert)}
        autoHideDuration={1500}
        onClose={() => setSuccessAlert(null)}
      >
        <Alert
          onClose={() => setSuccessAlert(null)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successAlert}
        </Alert>
      </Snackbar>

      {/* Confirmação de remoção */}
      <Dialog open={openDelete}>
        <DialogTitle>Quer apagar o serviço digital?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você confirma a remoção desse serviço digital para esse operador e
            todas as informações relacionadas?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => onDelete(id)}>SIM</Button>
          <Button onClick={(e) => setOpenDelete(false)} autoFocus>
            NÃO
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ServicoDigital;
