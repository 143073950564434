/**
 * Description: HBO Max MG/META Table
 * Componente para exibir a tabela de MG/META do HBO Max em forma de popup
 */

import React from 'react';

import {
  Button,
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { red, indigo, green, grey, blue } from '@mui/material/colors';
import { padding } from '@mui/system';

const styles = {
  tableContainer: {
    width: '100%',
    maxHeight: '60vh', // Add max height to enable scrolling
    overflow: 'auto',
    padding: 1,
  },
  tableHeader: {
    height: '100%',
    marginBottom: '8px',
  },
  tableRow: {
    marginBottom: '16px',
  },
  button: {
    marginBottom: 2,
    marginTop: 2,
    width: '100%',
    color: 'white',
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%', // Wider modal to accommodate table
    maxWidth: '800px',
    maxHeight: '80vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden', // Prevent modal overflow
  },
};

const HboMaxMgMetaTable = ({ hboTable, show, setShow }) => {
  const close = () => {
    setShow(false);
  };

  return (
    <Modal open={show}>
      <Box sx={styles.modal}>
        {Array.isArray(hboTable) && (
          <TableContainer component={Paper} sx={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row" align="right">
                    PORTE
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    BASE
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    MG/META
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hboTable.map((item, idx) => {
                  return (
                    <TableRow
                      key={idx}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      {Object.keys(item).map((k, idx2) => {
                        return (
                          <TableCell
                            key={`${idx2}-2`}
                            component="th"
                            scope="row"
                            align="right"
                          >
                            {item[k]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Button sx={styles.button} onClick={(e) => close(e)}>
          FECHAR
        </Button>
      </Box>
    </Modal>
  );
};

export default HboMaxMgMetaTable;
