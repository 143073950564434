import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
  useRef,
} from 'react';

import {
  IconButton,
  Box,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';

import { Backup, Save, Delete } from '@mui/icons-material';
import { red, indigo, green, grey, blue } from '@mui/material/colors';

import Loading from 'util/loading';
import BroadcasterContext from './context';
import * as actions from './actions';
import * as XLSX from 'xlsx';
import { keyframes } from '@mui/styled-engine';

const isValidJSON = (obj) => {
  // Try to parse the string as JSON
  try {
    JSON.parse(obj);
    // If parsing succeeds, return true
    return true;
  } catch (e) {
    // If parsing fails (due to invalid JSON), return false
    return false;
  }
};

const bounce = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(2);
  }
`;

const FlexiblePriceTable = ({ priceTable, isB2B, save }) => {
  const { state, dispatch } = useContext(BroadcasterContext);
  const [loading, setLoading] = useState(false);
  const [priceTableData, setPriceTableData] = useState([]);
  // const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const [errorAlert, setErrorAlert] = useState(null);
  const [warningAlert, setWarningAlert] = useState(null);
  const [successAlert, setSuccessAlert] = useState(null);

  // usado para destacar o botão de salvar
  const [blinking, setBlinking] = useState(false);

  const inputFile = useRef(null);

  const styles = {
    buttonBox: {
      marginBottom: 2,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    button: {
      color: 'white',
      backgroundColor: grey[500],
      marginLeft: 'auto',
      height: 'fit-content',
      margin: 1,
    },
    blink: {
      animation: `${bounce} 1s infinite ease`,
    },
  };

  // se a tabela informada é válida, a carrega
  useEffect(() => {
    let myTable = [];
    if (isValidJSON(priceTable) && Array.isArray(JSON.parse(priceTable))) {
      myTable = JSON.parse(priceTable);
    }
    setPriceTableData(myTable);
  }, [priceTable]);

  const parseFlexibleTableSheet = async function (aData) {
    try {
      const workBook = XLSX.read(aData, {
        type: 'buffer',
      });
      const worksheetNames = workBook.SheetNames;
      const wName = worksheetNames[0];
      const xlsjson = XLSX.utils.sheet_to_json(workBook.Sheets[wName]);

      const flexTableString = JSON.stringify(xlsjson);
      return flexTableString;
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    }
  };

  const uploadFlexibleTableSheet = async () => {
    // `current` points to the mounted file input element
    inputFile.current.value = null;
    inputFile.current.onchange = () => {
      try {
        setLoading(true);
        const sheetFile = inputFile.current.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
          const tableString = await parseFlexibleTableSheet(e.target.result);
          await updateState(tableString);
        };
        reader.readAsArrayBuffer(sheetFile);
      } catch (error) {
        console.log(error);
        setErrorAlert(error.toString());
      } finally {
        setLoading(false);
      }
    };

    inputFile.current.click();
  };

  async function updateState(aPriceTableString) {
    let b = Object.assign({}, state.broadcaster);
    if (isB2B) {
      b.flexiblePriceTableB2B = aPriceTableString;
    } else {
      b.flexibleTables = aPriceTableString;
    }
    dispatch({ type: actions.SET_BROADCASTER, payload: b });

    setBlinking(true);
    setWarningAlert('Não se esqueça de salvar as alterações');
    setTimeout(() => {
      setBlinking(false);
    }, 3500);
  }

  // remove table de preços
  async function onDeleteFlexTable() {
    await updateState('[]');
  }

  return (
    <Fragment>
      <Loading loading={loading} />

      {/* elemento que recebe o arquivo de upload */}
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        accept=".xls,.xlsx"
      />

      {/* Mensagens de sucesso  */}
      <Snackbar
        open={Boolean(successAlert)}
        autoHideDuration={1500}
        onClose={() => setSuccessAlert(null)}
      >
        <Alert
          onClose={() => setSuccessAlert(null)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successAlert}
        </Alert>
      </Snackbar>

      {/* Mensagens de aviso  */}
      <Snackbar
        open={Boolean(warningAlert)}
        autoHideDuration={2500}
        onClose={() => setWarningAlert(null)}
      >
        <Alert
          onClose={() => setWarningAlert(null)}
          severity="warning"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {warningAlert}
        </Alert>
      </Snackbar>

      {/* Mensagens de erro  */}
      <Snackbar
        open={Boolean(errorAlert)}
        autoHideDuration={2500}
        onClose={() => setErrorAlert(null)}
      >
        <Alert
          onClose={() => setErrorAlert(null)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorAlert}
        </Alert>
      </Snackbar>

      {/* barra de botoes  */}
      <Box sx={styles.buttonBox}>
        <Tooltip title="Salvar alterações">
          <IconButton
            onClick={(e) => save()}
            sx={[
              styles.button,
              {
                backgroundColor: green[500],
              },
              blinking && styles.blink,
            ]}
          >
            <Save />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remover tabela flexivel">
          <IconButton
            size="small"
            sx={[
              styles.button,
              {
                backgroundColor: red[500],
              },
            ]}
            onClick={() => onDeleteFlexTable()}
          >
            <Delete />
          </IconButton>
        </Tooltip>
        <Tooltip title="Upload de tabela de preços flexíveis">
          <IconButton
            onClick={(e) => uploadFlexibleTableSheet()}
            sx={[
              styles.button,
              {
                backgroundColor: blue[500],
              },
            ]}
          >
            <Backup />
          </IconButton>
        </Tooltip>
      </Box>

      {/* tabela de preços  */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          {priceTableData.length > 0 && (
            <TableHead>
              <TableRow>
                {Object.keys(priceTableData[0]).map((item, idx) => {
                  return (
                    <TableCell
                      component="th"
                      scope="row"
                      key={idx}
                      align="right"
                    >
                      {item}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {priceTableData.map((item, idx) => {
              return (
                <TableRow key={idx}>
                  {Object.keys(item).map((k, idx2) => {
                    return (
                      <TableCell
                        key={`${idx2}-2`}
                        component="th"
                        scope="row"
                        align="right"
                      >
                        {item[k]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};
export default FlexiblePriceTable;
