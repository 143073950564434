import React, { useContext, useEffect, useState, Fragment } from 'react';

import { Navigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import validaCNPJ from 'util/CnpjCheck';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import * as mutations from 'graphql/mutations';
import * as customGql from 'graphql/custom/functions';
import { API, graphqlOperation } from 'aws-amplify';
import { v4 } from 'uuid';
import { Backup, Add, Delete, ExpandMore, Save } from '@mui/icons-material';
import {
  Typography,
  Box,
  Fab,
  IconButton,
  Tooltip,
  InputLabel,
  Switch,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
} from '@mui/material';

import Alert from 'components/Neo/Alert';
import Loading from 'util/loading';
import { red, indigo, green, grey, blue } from '@mui/material/colors';

import OperatorContext from './context';
import * as actions from './actions';
import ContractItem from './contract';
import ServicoDigital from './servicoDigital';
import RegionItem from './region';
import ModalBroadcaster from './broadcasterList';
import AddRegion from './addRegion';
import * as customQueries from 'graphql/custom/queries';
import ExternalUsers from '../../../../components/externalUsers';
import OperatorVods from './vods';
import { minWidth } from '@mui/system';

const styles = {
  inputField: {
    padding: 1,
  },
  formControl: {
    minWidth: '220px',
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: 2,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
};

const OperatorItem = ({ myOperatorId }) => {
  const { state, dispatch } = useContext(OperatorContext);
  const [loading, setLoading] = useState(false);

  const [newItem, setNewItem] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [showAlertDeleteOperator, setShowAlertDeleteOperator] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [gatewayIds, setGatewayIds] = useState([]);

  const [technologies, setTechnologies] = useState([]);
  const [headends, setHeadends] = useState([]);

  const listGatewayIds = async function () {
    try {
      fetch(
        'https://20arn6e6dj.execute-api.us-east-1.amazonaws.com/production/listoperators2?resource_id=urn:*'
      )
        .then((res) => res.json())
        .then((data) => {
          setGatewayIds(data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const listTechnologies = async function () {
    try {
      setLoading(true);
      const result = await API.graphql(
        graphqlOperation(customQueries.listEnum, {
          enumType: 'Technology',
        })
      );
      const t = [];
      for (const e of result.data.__type.enumValues) {
        t.push(e.name);
      }
      setTechnologies(t);
    } catch (error) {
      NotificationManager.error(error.toString(), null, 2000);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // lista os ENUMs dos headends definidas no .schema do banco de dados
  const listHeadends = async function () {
    try {
      setLoading(true);

      const result = await API.graphql(
        graphqlOperation(customQueries.listEnum, {
          enumType: 'Headend',
        })
      );
      const t = [];
      for (const e of result.data.__type.enumValues) {
        t.push(e.name);
      }
      setHeadends(t);
    } catch (error) {
      NotificationManager.error(error.toString(), null, 2000);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const addGrownthPlans = function () {
    const s = {
      id: v4(),
      svaId: '',
      planTable: [],
      operatorId: state.operator.id,
      sva: {
        id: null,
        name: '',
        broadcasterId: null,
      },
    };

    dispatch({ type: actions.ADD_GROWTHPLANS, payload: s });
  };
  function plain(str) {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  function compareContracts(a, b) {
    if (plain(a.broadcaster.trade) > plain(b.broadcaster.trade)) {
      return 1;
    }

    if (plain(a.broadcaster.trade) < plain(b.broadcaster.trade)) {
      return -1;
    }

    return 0;
  }

  function compareRegions(a, b) {
    if (plain(a.municipality.state) > plain(b.municipality.state)) {
      return 1;
    }

    if (plain(a.municipality.state) < plain(b.municipality.state)) {
      return -1;
    }

    if (plain(a.municipality.name) > plain(b.municipality.name)) {
      return 1;
    }

    if (plain(a.municipality.name) < plain(b.municipality.name)) {
      return -1;
    }

    return 0;
  }

  function checkRegionEnabled(aItem) {
    let d = Boolean(aItem.disabled);
    return !d;
  }

  // useEffect(() => {
  //   listDistributions();

  //   return function cleanup() {};
  // }, [setDistributions]);

  function handleChange(event) {
    let f = event.target.name;
    let v = event.target.value;
    let op = Object.assign({}, state.operator);
    op[f] = v;
    dispatch({
      type: actions.SET_OPERATOR,
      payload: op,
    });
  }

  function handleToggle(aEvent) {
    let op = Object.assign({}, state.operator);
    op.disabled = !aEvent.target.checked;

    const dt = new Date();
    op.disabledDate = dt.toISOString();

    dispatch({
      type: actions.SET_OPERATOR,
      payload: op,
    });
  }

  function handleToggleEvolution(aEvent) {
    let op = Object.assign({}, state.operator);
    op.evolution = aEvent.target.checked;

    dispatch({
      type: actions.SET_OPERATOR,
      payload: op,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setLoading(true);

      if (validaCNPJ(state.operator.corporate_registry) === false) {
        NotificationManager.error('CNPJ inválido', null, 3000);
        return;
      }

      let operation;
      let id;

      if (newItem) {
        operation = mutations.createOperator;
        id = v4();
      } else {
        operation = mutations.updateOperator;
        id = state.operator.id;
      }

      const operatorInput = {
        company_name: state.operator.company_name,
        corporate_registry: state.operator.corporate_registry,
        trade: state.operator.trade,
        id: id,
        disabled: Boolean(state.operator.disabled),
        disabledDate: state.operator.disabledDate || '',
        headend: state.operator.headend,
        evolution: state.operator.evolution,
        econoGroup: state.operator.econoGroup,
        gatewayId: state.operator.gatewayId,
      };

      const result = await API.graphql(
        graphqlOperation(operation, { input: operatorInput })
      );
      setNewItem(false);

      // atualiza o operator no state
      await getOperator(id);
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 3000);
    } finally {
      setLoading(false);
    }
  }

  async function onDeleteOperator() {
    try {
      setLoading(true);

      const result = await API.graphql(
        graphqlOperation(mutations.deleteOperator, {
          input: {
            id: state.operator.id,
          },
        })
      );

      NotificationManager.success('Dados removidos com sucesso', null, 3000);
      setRedirect(true);
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 3000);
    } finally {
      setShowAlertDeleteOperator(false);
      setLoading(false);
    }
  }

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getOperator = async function (aId) {
    try {
      setLoading(true);

      const op = await customGql.getOperator(aId);
      dispatch({
        type: actions.SET_OPERATOR,
        payload: op,
      });
    } catch (error) {
      console.log(error);
      const msg = error.message || error.toString();
      NotificationManager.error(msg, null, 3000);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Boolean(myOperatorId) === false) {
      setRedirect(true);
      return;
    }

    listTechnologies();
    listHeadends();
    listGatewayIds();

    async function reloadOperator() {
      await getOperator(myOperatorId);
    }

    if (myOperatorId !== '000') {
      setNewItem(false);
      reloadOperator();
    }
  }, [myOperatorId]);

  const onAddBroadcaster = async function (aEvent) {
    dispatch({
      type: actions.MODAL_BROADCASTER,
      payload: true,
    });
  };

  const onAddRegion = async function (aEvent) {
    dispatch({
      type: actions.MODAL_REGION,
      payload: true,
    });
  };

  async function onDeleteGrowthPlans(id) {
    try {
      setLoading(true);

      const result = await API.graphql(
        graphqlOperation(mutations.deleteGrowthPlan, {
          input: {
            id: id,
          },
        })
      );
      dispatch({ type: actions.REMOVE_GROWTPLANS, payload: id });
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 3000);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Fragment>
      {redirect && <Navigate to="/" replace state={state} />}
      <Loading loading={loading} />
      <ModalBroadcaster />
      <AddRegion technologies={technologies} />

      <Alert
        show={showAlertDeleteOperator}
        onCancel={() => setShowAlertDeleteOperator(false)}
        onOk={onDeleteOperator}
        body={
          'Remover Operadora? Isso irá remover a operadora, seu contrato e lineup e suas praças.'
        }
        title={'Remover operadora'}
      />

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          padding: 2,
          alignItems: 'center',
        }}
      >
        <h2>{newItem ? 'Nova operadora' : state.operator.trade}</h2>

        <Tooltip title="Remover operadora" display={newItem ? 'none' : 'block'}>
          <Box>
            <IconButton
              onClick={() => setShowAlertDeleteOperator(true)}
              sx={{
                color: 'white',
                backgroundColor: red[500],
                marginLeft: 'auto',
                height: 'fit-content',
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>

      {/* Dados cadastrais */}
      <Accordion
        onChange={handleChangeAccordion('panelDados')}
        slotProps={{ transition: { mountOnEnter: true } }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>{'Dados cadastrais'}</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <form
            id="operatorData"
            autoComplete="off"
            onSubmit={handleSubmit} //submit do Dados Cadastrais
          >
            <Box sx={styles.inputRow}>
              <FormControl
                variant="standard"
                sx={[styles.formControl, { minWidth: '360px' }]}
              >
                <TextField
                  name="company_name"
                  label="Razão Social"
                  value={state.operator.company_name}
                  onChange={handleChange}
                  required
                  variant="standard"
                  disabled={Boolean(state.operator.disabled)}
                  sx={styles.inputField}
                />
              </FormControl>

              <FormControl variant="standard" sx={styles.formControl}>
                <TextField
                  name="trade"
                  label="Marca"
                  value={state.operator.trade}
                  onChange={handleChange}
                  required
                  variant="standard"
                  disabled={Boolean(state.operator.disabled)}
                  sx={styles.inputField}
                />
              </FormControl>

              <FormControl variant="standard" sx={styles.formControl}>
                <InputMask
                  mask="99.999.999/9999-99"
                  value={state.operator.corporate_registry}
                  onChange={handleChange}
                  disabled={Boolean(state.operator.disabled)}
                  sx={styles.inputField}
                >
                  {() => (
                    <TextField
                      name="corporate_registry"
                      label="CNPJ"
                      margin="normal"
                      required
                      variant="standard"
                    />
                  )}
                </InputMask>
              </FormControl>

              <FormControl sx={styles.formControl} variant="standard">
                <InputLabel>Headend</InputLabel>
                <Select
                  name="headend"
                  value={state.operator.headend}
                  onChange={handleChange}
                  variant="standard"
                  disabled={Boolean(state.operator.disabled)}
                  sx={styles.inputField}
                >
                  {headends.map((item, idx) => (
                    <MenuItem key={idx} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={styles.inputRow}>
              <FormControl variant="standard">
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      onClick={(event) => handleToggleEvolution(event)}
                      checked={Boolean(state.operator.evolution)}
                    />
                  }
                  label="Incluir no relatório de evolução"
                />
              </FormControl>

              <FormControl variant="standard" sx={styles.formControl}>
                <TextField
                  name="econoGroup"
                  label="Grupo Econômico"
                  value={state.operator.econoGroup}
                  onChange={handleChange}
                  variant="standard"
                  disabled={Boolean(state.operator.disabled)}
                  sx={[styles.inputField, { maxWidth: '240px' }]}
                />
              </FormControl>

              <FormControl sx={styles.formControl} variant="standard">
                <InputLabel>Gateway Id</InputLabel>
                <Select
                  name="gatewayId"
                  value={state.operator.gatewayId}
                  onChange={handleChange}
                  variant="standard"
                  disabled={Boolean(state.operator.disabled)}
                  sx={[styles.inputField, { maxWidth: '240px' }]}
                >
                  {gatewayIds.map((item, idx) => (
                    <MenuItem key={idx} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={[styles.inputRow, { justifyContent: 'flex-end' }]}>
              <FormControl variant="standard">
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      onClick={(event) => handleToggle(event)}
                      checked={!Boolean(state.operator.disabled)}
                    />
                  }
                  label="Habilitado"
                />
              </FormControl>

              <FormControl variant="standard">
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  sx={styles.inputField}
                >
                  <Save />
                  Salvar
                </Button>
              </FormControl>
            </Box>
          </form>
        </AccordionDetails>
      </Accordion>

      {/* Praças */}
      <Accordion
        expanded={expanded === 'panelRegions'}
        disabled={newItem || state.operator.trade.length === 0}
        onChange={handleChangeAccordion('panelRegions')}
        slotProps={{ transition: { mountOnEnter: true } }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>Praças</AccordionSummary>
        <AccordionDetails>
          {expanded === 'panelRegions' && (
            <Fragment>
              <Button
                variant="contained"
                onClick={(event) => onAddRegion(event)}
                color="primary"
                disabled={Boolean(state.operator.disabled)}
              >
                <Add />
                Adicionar praça
              </Button>
              {state.operator.regions.items
                .filter(checkRegionEnabled)
                .sort(compareRegions)
                .map((item, idx) => (
                  <RegionItem
                    region={item}
                    key={item.id}
                    technologies={technologies}
                    operatorId={state.operator.id}
                  />
                ))}
            </Fragment>
          )}
        </AccordionDetails>
      </Accordion>

      {/*Lineup */}
      <Accordion
        expanded={expanded === 'panelContracts'}
        disabled={newItem || state.operator.trade.length === 0}
        onChange={handleChangeAccordion('panelContracts')}
        slotProps={{ transition: { mountOnEnter: true } }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>LineUp</AccordionSummary>

        <AccordionDetails>
          {expanded === 'panelContracts' && (
            <Fragment>
              <Button
                variant="contained"
                onClick={(event) => onAddBroadcaster(event)}
                color="primary"
                disabled={Boolean(state.operator.disabled)}
              >
                <Add />
                Adicionar programadora
              </Button>
              {state.operator.contracts.items
                .sort(compareContracts)
                .map((item, idx) => (
                  <ContractItem contract={item} key={item.id} />
                ))}
            </Fragment>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Servicos digitais */}
      <Accordion
        expanded={expanded === 'panelGrowthPlans'}
        disabled={newItem || state.operator.trade.length === 0}
        onChange={handleChangeAccordion('panelGrowthPlans')}
        slotProps={{
          transition: {
            mountOnEnter: true,
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          Serviços Digitais
        </AccordionSummary>

        <AccordionDetails>
          {expanded === 'panelGrowthPlans' && (
            <Fragment>
              <Button
                variant="contained"
                onClick={addGrownthPlans}
                color="primary"
              >
                <Add />
                Adicionar Serviço Digital
              </Button>

              {state.operator.growthPlans.items.map((item, idx) => (
                <ServicoDigital
                  sva={item}
                  onDelete={onDeleteGrowthPlans}
                  key={item.id}
                  id={item.id}
                />
              ))}
            </Fragment>
          )}
        </AccordionDetails>
      </Accordion>

      {/* VODs */}
      <Accordion
        expanded={expanded === 'operatorVods'}
        disabled={newItem || state.operator.trade.length === 0}
        onChange={handleChangeAccordion('operatorVods')}
        slotProps={{ transition: { mountOnEnter: true } }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>VODs</AccordionSummary>

        <AccordionDetails>
          {expanded === 'operatorVods' && (
            <Fragment>
              <OperatorVods operatorId={state.operator.id} />
            </Fragment>
          )}
        </AccordionDetails>
      </Accordion>

      {/* User externo */}
      <Accordion
        expanded={expanded === 'panelExternalUser'}
        disabled={newItem || state.operator.trade.length === 0}
        onChange={handleChangeAccordion('panelExternalUser')}
        slotProps={{ transition: { mountOnEnter: true } }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          Usuário externo
        </AccordionSummary>

        <AccordionDetails>
          {expanded === 'panelExternalUser' && (
            <ExternalUsers companyId={state.operator.id} />
          )}
        </AccordionDetails>
      </Accordion>
      <NotificationContainer />
    </Fragment>
  );
};
export default OperatorItem;
