import React, {
  useContext,
  useRef,
  useEffect,
  Fragment,
  useState,
} from 'react';
import {
  Backup,
  GetApp,
  Delete,
  Map,
  LocationCity,
  Logout,
} from '@mui/icons-material';
import {
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  Fab,
  Typography,
  Chip,
  Avatar,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  CircularProgress,
  BottomNavigation,
  BottomNavigationAction,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Paper,
  Tooltip,
  IconButton,
  Badge,
  setRef,
} from '@mui/material';

import { red, indigo, green, grey, blue } from '@mui/material/colors';

import * as customQueries from 'graphql/custom/queries';
import * as customGql from 'graphql/custom/functions';
import * as mutations from 'graphql/mutations';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import Loading from 'util/loading';
import ListFiles from 'util/ListFiles';

const initialOperatorValue = {
  id: '',
  company_name: '',
  trade: '',
  corporate_registry: '',
  regions: {
    items: [],
  },
  contracts: {
    items: [],
  },
  disabled: false,
};

const initialRegionValue = {
  id: '',
  municipality: {
    state: '',
    name: '',
  },
};

const initialDateValue = new Date(
  new Date().getFullYear(),
  new Date().getMonth() - 1,
  10
);

const styles = {
  baseHeader: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 3,
    padding: 1,
    margin: 1,
    justifyContent: 'flex-start',
  },
};

const UserbasePage = () => {
  const [loading, setLoading] = useState(false);

  const [includeDisabled, setIncludeDisabled] = useState(false);
  const [showReportsCheckedInput, setShowReportsCheckedInput] = useState(false);

  const [ordenaPorEstado, setOrdenaPorEstado] = useState(1);
  const [uploadProgress, setUploadProgress] = useState(0);

  const [operators, setOperators] = useState([]);
  const [sheets, setSheets] = useState([]);

  const [currentOperator, setCurrentOperator] = useState(initialOperatorValue);
  const [currentRegion, setCurrentRegion] = useState(initialRegionValue);
  const [refDate, setRefDate] = useState(initialDateValue);

  const [errorAlert, setErrorAlert] = useState(null);
  const [successAlert, setSuccessAlert] = useState(null);
  const [openDeleteFileDialog, setOpenDeleteFileDialog] = useState(false);

  const inputFile = useRef(null);

  // array com a listagem dos anos, a partir de 2020
  const years = Array.from(
    { length: new Date().getFullYear() - 2019 },
    (_, i) => new Date().getFullYear() - i
  );

  function plain(str) {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  function toggleDrawer(region) {
    if (region === null) {
      setCurrentRegion(initialRegionValue);
    } else {
      setCurrentRegion(region);
    }
  }

  function compareRegion(a, b) {
    if (ordenaPorEstado === 0) {
      if (plain(a.municipality.state) > plain(b.municipality.state)) {
        return 1;
      }

      if (plain(a.municipality.state) < plain(b.municipality.state)) {
        return -1;
      }
    }

    if (plain(a.municipality.name) > plain(b.municipality.name)) {
      return 1;
    }

    if (plain(a.municipality.name) < plain(b.municipality.name)) {
      return -1;
    }

    return 0;
  }

  function compareOperator(a, b) {
    if (plain(a.trade) > plain(b.trade)) {
      return 1;
    }

    if (plain(a.trade) < plain(b.trade)) {
      return -1;
    }

    return 0;
  }

  async function handleChangeOperator(event) {
    try {
      setLoading(true);
      const id = event.target.value;
      const op = await customGql.getOperator(id);
      setCurrentOperator(op);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  }

  function handleChangeMonth(event) {
    const m = event.target.value;
    let d = new Date(refDate);
    d.setMonth(m);
    setRefDate(d);
  }

  function handleChangeYear(event) {
    const y = event.target.value;
    let d = new Date(refDate);
    d.setFullYear(y);
    setRefDate(d);
  }

  async function loadSpreadsheets() {
    const m = (refDate.getMonth() + 1).toString().padStart(2, '0');
    const y = refDate.getFullYear().toString();
    const prefix = y + '/' + m + '/' + currentOperator.id + '/';

    try {
      setLoading(true);
      const files = await ListFiles(prefix);

      let mySheets = [];

      // todos arquivos do operador, para aquele período
      for (const s3file of files) {
        let keys = s3file.key.split('/');
        let y = keys[0]; // year
        let m = keys[1]; // month
        const operatorId = keys[2]; // operator
        const regionId = keys[3]; // region
        let f = keys[4]; // fileName

        if (mySheets[operatorId] == null) {
          mySheets[operatorId] = [];
        }
        mySheets[operatorId][regionId] = {
          fileName: f,
          lastModified: s3file.lastModified,
        };
      }

      setSheets(mySheets);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  }

  // initial
  useEffect(() => {
    async function listOperators() {
      try {
        setLoading(true);
        const result = await customGql.listAll(customQueries.listOperators);
        setOperators(result);
      } catch (error) {
        console.log(error);
        setErrorAlert(error.toString());
      } finally {
        setLoading(false);
      }
    }

    listOperators();

    return function cleanup() {};
  }, []);

  // refDate, currentOperator
  useEffect(() => {
    loadSpreadsheets();
    let dt = new Date();
    const month = dt.getMonth();
    const year = dt.getFullYear();
    if (
      (refDate.getMonth() === month - 1 && refDate.getFullYear() === year) ||
      (refDate.getFullYear() === year - 1 && Boolean(currentOperator.id))
    ) {
      setShowReportsCheckedInput(true);
    } else {
      setShowReportsCheckedInput(false);
    }
  }, [refDate, currentOperator]);

  const handleReportsChecked = async function (aEvent) {
    try {
      setLoading(true);

      // Quando o relatório foi conferido, o usuário externo fica desabilitado para subir/apagar as planilhas daquele mês
      let op = Object.assign({}, currentOperator);
      op.externalDisabled = aEvent.target.checked;
      const operatorInput = {
        company_name: currentOperator.company_name,
        corporate_registry: currentOperator.corporate_registry,
        trade: currentOperator.trade,
        id: currentOperator.id,
        externalDisabled: op.externalDisabled,
      };

      const result = await API.graphql(
        graphqlOperation(mutations.updateOperator, {
          input: operatorInput,
        })
      );
      setCurrentOperator(op);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const uploadSheet = async (aOperator, aRegion) => {
    /*
      File name structure
      YYYY/MM/op_id/reg_id/name
      YYYY   - year
      MM     - month (01 to 12)
      op_id  - operator id
      reg_id - region_id
      name   - original file name
    */

    if (!Boolean(aOperator) || !Boolean(aRegion)) {
      return;
    }

    // `current` points to the mounted file input element
    inputFile.current.value = null;
    inputFile.current.onchange = () => {
      try {
        setLoading(true);

        const sheetFile = inputFile.current.files[0];
        const reader = new FileReader();
        setUploadProgress(0);

        reader.onload = async (e) => {
          const m = (refDate.getMonth() + 1).toString().padStart(2, '0');
          const y = refDate.getFullYear().toString();
          const name =
            y +
            '/' +
            m +
            '/' +
            aOperator +
            '/' +
            aRegion +
            '/' +
            sheetFile.name;

          const result = await Storage.put(name, e.target.result, {
            cacheControl: 'no-cache',
            level: 'public',
            progressCallback(progress) {
              if (progress.total !== 0) {
                setUploadProgress((100 * progress.loaded) / progress.total);
              }
            },
          });

          setUploadProgress(0);

          setSuccessAlert('Arquivo enviado');
          loadSpreadsheets();
        };
        reader.readAsArrayBuffer(sheetFile);
      } catch (error) {
        console.log(error);
        setErrorAlert(error.toString());
      } finally {
        setLoading(false);
        toggleDrawer(null);
      }
    };

    inputFile.current.click();
  };

  async function downloadSheet(operator, region) {
    try {
      setLoading(true);

      // pega a chave (nome completo, com caminho) do arquivo no bucket S3
      const m = (refDate.getMonth() + 1).toString().padStart(2, '0');
      const y = refDate.getFullYear().toString();
      const prefix = y + '/' + m + '/';
      const fileName = sheets[operator][region].fileName;
      const key = prefix + operator + '/' + region + '/' + fileName;

      // pega a URL de download temporário
      const result = await Storage.get(key, {
        level: 'public',
        expires: 60,
        download: true,
      });
      const url = URL.createObjectURL(result.Body);
      // abre a janela de download
      let dwnld = document.createElement('a');
      dwnld.setAttribute('href', url);
      dwnld.setAttribute('download', fileName);
      document.body.appendChild(dwnld);

      const evt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
        clientX: 20,
        /* whatever properties you want to give it */
      });
      dwnld.dispatchEvent(evt);
      document.body.removeChild(dwnld);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  }

  // retorna apenas as que não estão habilitadas,
  // a não ser que a opção de "incluir desabilitadas"
  // esteja selecionada
  function disabledFilter(item) {
    if (includeDisabled) {
      return true;
    } else {
      return !Boolean(item.disabled);
    }
  }

  async function onDeleteSheet() {
    let m = (refDate.getMonth() + 1).toString().padStart(2, '0');
    let y = refDate.getFullYear().toString();
    let prefix =
      y + '/' + m + '/' + currentOperator.id + '/' + currentRegion.id + '/';

    try {
      setLoading(true);

      const files = await ListFiles(prefix);

      for (const file of files) {
        const result = await Storage.remove(file.key, { level: 'public' });

        let mySheets = Object.assign({}, sheets);
        delete mySheets[currentOperator.id][currentRegion.id];
        setSheets(mySheets);
      }

      setSuccessAlert('Arquivo removido com sucesso');
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
      setOpenDeleteFileDialog(false);
      toggleDrawer(null);
    }
  }

  function enabledFilter(item) {
    return !Boolean(item.disabled);
  }

  function handleIncludeDisabled(aEvent) {
    setIncludeDisabled(aEvent.target.checked);
  }

  return (
    <div className="app-wrapper">
      <Loading loading={loading} />

      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        accept=".xls,.xlsx"
      />

      <Box sx={styles.baseHeader}>
        <Box>
          <FormControl sx={{ width: 130 }} variant="standard">
            <InputLabel>Operador</InputLabel>
            <Select
              name="operator"
              value={currentOperator.id}
              onChange={(e) => handleChangeOperator(e)}
              variant="standard"
            >
              {operators
                .filter(disabledFilter)
                .sort(compareOperator)
                .map((item, idx) => (
                  <MenuItem key={idx} value={item.id}>
                    {item.trade}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        <Box>
          <FormControl className="w-100" variant="standard">
            <InputLabel>Mês</InputLabel>
            <Select
              name="month"
              value={refDate.getMonth()}
              onChange={(e) => handleChangeMonth(e)}
              variant="standard"
            >
              {[...Array(12).keys()].map((item, idx) => (
                <MenuItem key={idx} value={idx}>
                  {(idx + 1).toString().padStart(2, '0')}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box>
          <FormControl className="w-100" variant="standard">
            <InputLabel>Ano</InputLabel>
            <Select
              name="month"
              value={refDate.getFullYear()}
              onChange={(e) => handleChangeYear(e)}
              variant="standard"
            >
              {years.map((item, idx) => (
                <MenuItem key={idx} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {showReportsCheckedInput && (
          <Box>
            <FormGroup>
              <FormControlLabel
                sx={{ justifyContent: 'right' }}
                control={
                  <Checkbox
                    checked={Boolean(currentOperator.externalDisabled)}
                    onChange={(e) => handleReportsChecked(e)}
                  />
                }
                label="relatórios conferidos"
              />
            </FormGroup>
          </Box>
        )}

        <Box>
          <FormGroup>
            <FormControlLabel
              sx={{ justifyContent: 'right' }}
              control={
                <Checkbox
                  checked={includeDisabled}
                  onChange={(e) => handleIncludeDisabled(e)}
                />
              }
              label="Incluir desabilitadas"
            />
          </FormGroup>
        </Box>

        <Box>
          {uploadProgress > 0 && (
            <Box position="relative" display="inline-flex">
              <CircularProgress variant="determinate" value={uploadProgress} />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="caption"
                  component="div"
                  color="textSecondary"
                >
                  {`${Math.round(uploadProgress)}%`}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {currentOperator.regions.items.length > 0 && (
        <Box>
          <Drawer
            anchor="right"
            open={currentRegion.id.length > 0}
            onClose={(e) => toggleDrawer(null)}
          >
            <List>
              <ListItem dense={true}>
                <ListItemIcon>
                  <LocationCity />
                </ListItemIcon>

                <ListItemText
                  primary={
                    currentRegion.municipality.name +
                    ' / ' +
                    currentRegion.municipality.state
                  }
                />
              </ListItem>
              <Divider />

              {sheets[currentOperator.id] &&
                sheets[currentOperator.id][currentRegion.id] && (
                  <Fragment>
                    <ListItem
                      button
                      onClick={(e) =>
                        downloadSheet(currentOperator.id, currentRegion.id)
                      }
                    >
                      <ListItemIcon>
                        <GetApp />
                      </ListItemIcon>

                      <ListItemText
                        disableTypography
                        primary={
                          <Typography variant="subtitle2" color="textSecondary">
                            {
                              sheets[currentOperator.id][currentRegion.id]
                                .fileName
                            }
                          </Typography>
                        }
                        secondary={
                          <Typography variant="caption" color="textSecondary">
                            {sheets[currentOperator.id][
                              currentRegion.id
                            ].lastModified.toLocaleString()}
                          </Typography>
                        }
                      />
                    </ListItem>

                    <Divider />

                    <ListItem>
                      <Tooltip title="Remover planilha">
                        <IconButton
                          sx={{
                            color: 'white',
                            backgroundColor: red[500],
                            marginLeft: 'auto',
                          }}
                          onClick={(e) => {
                            setOpenDeleteFileDialog(true);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                    <Divider />
                  </Fragment>
                )}

              {!(
                sheets[currentOperator.id] &&
                sheets[currentOperator.id][currentRegion.id]
              ) && (
                <ListItem>
                  <Tooltip title="Upload de planilha">
                    <IconButton
                      sx={{
                        color: 'white',
                        backgroundColor: indigo[500],
                        marginLeft: 'auto',
                      }}
                      onClick={(e) =>
                        uploadSheet(currentOperator.id, currentRegion.id)
                      }
                    >
                      <Backup />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              )}

              <ListItem>
                <Tooltip title="Fechar">
                  <IconButton
                    onClick={(e) => toggleDrawer(null)}
                    sx={{
                      color: 'white',
                      backgroundColor: green[500],
                      marginLeft: 'auto',
                      height: 'fit-content',
                    }}
                  >
                    <Logout />
                  </IconButton>
                </Tooltip>
              </ListItem>
            </List>
          </Drawer>

          {/* Ordenação por Estado/Cidade ou Cidade */}
          <BottomNavigation
            value={ordenaPorEstado}
            showLabels
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              padding: 1,
              margin: 1,
            }}
            onChange={(e, newValue) => setOrdenaPorEstado(newValue)}
          >
            <BottomNavigationAction label="Estado/Cidade" icon={<Map />} />
            <BottomNavigationAction label="Cidade" icon={<LocationCity />} />
          </BottomNavigation>

          <Paper
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: 1,
              width: '100%',
              minHeight: 200,
              margin: 2,
              padding: 2,
            }}
          >
            {currentOperator.regions.items
              .filter(enabledFilter)
              .sort(compareRegion)
              .map((region, reIdx) => {
                const hasSheet =
                  sheets[currentOperator.id] &&
                  sheets[currentOperator.id][region.id];
                return (
                  <Box key={reIdx + 'div'}>
                    <Chip
                      avatar={<Avatar>{region.municipality.state}</Avatar>}
                      label={region.municipality.name}
                      size="small"
                      sx={{
                        color: hasSheet ? 'white' : grey[500],
                        backgroundColor: hasSheet ? indigo[500] : grey[50],
                        marginLeft: 'auto',
                      }}
                      onClick={(e) => toggleDrawer(region)}
                    />
                  </Box>
                );
              })}
          </Paper>
        </Box>
      )}

      {currentOperator.regions.items.length === 0 && (
        <Paper
          elevation={3}
          sx={{
            margin: 2,
            padding: 2,
          }}
        >
          <InputLabel>'Sem dados para exibição'</InputLabel>
        </Paper>
      )}

      {/* Mensagens de erro  */}
      <Snackbar
        open={Boolean(errorAlert)}
        autoHideDuration={3500}
        onClose={() => setErrorAlert(null)}
      >
        <Alert
          onClose={() => setErrorAlert(null)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorAlert}
        </Alert>
      </Snackbar>

      {/* Mensagens de sucesso  */}
      <Snackbar
        open={Boolean(successAlert)}
        autoHideDuration={1500}
        onClose={() => setSuccessAlert(null)}
      >
        <Alert
          onClose={() => setSuccessAlert(null)}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {successAlert}
        </Alert>
      </Snackbar>

      {/* Confirmação de remoção de arquivo */}
      <Dialog open={openDeleteFileDialog}>
        <DialogTitle>Quer apagar o arquivo?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Você confirma a remoção desse arquivo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => onDeleteSheet()}>SIM</Button>
          <Button onClick={(e) => setOpenDeleteFileDialog(false)} autoFocus>
            NÃO
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserbasePage;
