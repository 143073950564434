export const getBroadcaster = /* GraphQL */ `
  query ($id: ID!, $nextToken: String) {
    getBroadcaster(id: $id) {
      id
      trade
      company_name
      corporate_registry
      flexibleTables
      flexiblePriceTableB2B
      disabled
      disabledDate
      layout
      layoutSva
      layoutVod
      increasePercentage
      hboMaxMgMetaTable {
        porte
        base
        mg
      }
      channels(limit: 200, nextToken: $nextToken) {
        items {
          id
          name
          disabled
          paytv
          createdAt
          broadcaster {
            id
            disabled
          }
        }
        nextToken
      }
      svas(limit: 200) {
        items {
          id
          name
          broadcasterId
          incTable {
            userbase
            value
            discount
          }
          isIncTable
          disabled
          createdAt
          updatedAt
          urn
        }
        nextToken
      }
      vod(limit: 200) {
        items {
          id
          name
          broadcasterId
          disabled
          disabledDate
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;

export const getOperator = /* GraphQL */ `
  query ($id: ID!, $nextTokenRegions: String, $nextTokenContracts: String) {
    getOperator(id: $id) {
      id
      trade
      company_name
      corporate_registry
      disabled
      disabledDate
      headend
      evolution
      gatewayId
      externalDisabled
      econoGroup
      regions(limit: 200, nextToken: $nextTokenRegions) {
        items {
          id
          technology
          disabled
          municipality {
            id
            code
            name
            state
          }
        }
        nextToken
      }
      growthPlans(limit: 200) {
        items {
          id
          svaId
          combo
          disabled
          planTable {
            userbase
            month
            year
          }
          operatorId
          createdAt
          updatedAt
          clientNumber
          contractNumber
          startDate
          isB2B
          sva {
            id
            name
            broadcasterId
          }
        }
        nextToken
      }
      contracts(limit: 200, nextToken: $nextTokenContracts) {
        items {
          id
          contract_number
          customer_code
          createdAt
          broadcaster {
            id
            trade
          }
        }
        nextToken
      }
    }
  }
`;

export const getContract = /* GraphQL */ `
  query GetContract($id: ID!, $nextToken: String) {
    getContract(id: $id) {
      id
      contract_number
      customer_code
      distribution
      packing
      startDate
      billingCode
      createdAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
      }
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        disabled
      }
      lineup(nextToken: $nextToken) {
        items {
          id
          disabled
          penetration
          createdAt
          channel {
            id
            name
            disabled
            paytv
          }
          contract {
            id
            broadcaster {
              id
              disabled
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const listBroadcasters = /* GraphQL */ `
  query ListBroadcasters(
    $filter: ModelBroadcasterFilterInput
    $limit: Int
    $nextToken: String
    $nextTokenChannels: String
  ) {
    listBroadcasters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        disabled
        disabledDate
        channels(limit: 200, nextToken: $nextTokenChannels) {
          items {
            id
            name
            disabled
            paytv
          }
          nextToken
        }
        vod(limit: 200) {
          items {
            id
            name
            disabled
            disabledDate
          }
        }
      }
      nextToken
    }
  }
`;

export const listOperators = /* GraphQL */ `
  query ListOperators(
    $filter: ModelOperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOperators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        trade
        disabled
        disabledDate
        createdAt
      }
      nextToken
    }
  }
`;

export const listEnum = /* GraphQL */ `
  query ($enumType: String!) {
    __type(name: $enumType) {
      name
      enumValues {
        name
      }
    }
  }
`;

export const warningByPeriod = /* GraphQL */ `
  query WarningByPeriod($period: String, $nextToken: String) {
    warningByPeriod(period: $period, nextToken: $nextToken) {
      items {
        id
        operator {
          id
          trade
        }
      }
      nextToken
    }
  }
`;

export const warningByOperator = /* GraphQL */ `
  query WarningByOperator(
    $operatorId: ID
    $period: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportWarningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    warningByOperator(
      operatorId: $operatorId
      period: $period
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        warning
        sheet
        warningType
        region {
          id
          municipality {
            name
            state
          }
        }
      }
      nextToken
    }
  }
`;
