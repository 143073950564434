/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateOperator = /* GraphQL */ `
  subscription OnCreateOperator {
    onCreateOperator {
      id
      company_name
      trade
      corporate_registry
      disabled
      disabledDate
      headend
      evolution
      gatewayId
      econoGroup
      externalDisabled
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      growthPlans {
        nextToken
        __typename
      }
      vods {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateOperator = /* GraphQL */ `
  subscription OnUpdateOperator {
    onUpdateOperator {
      id
      company_name
      trade
      corporate_registry
      disabled
      disabledDate
      headend
      evolution
      gatewayId
      econoGroup
      externalDisabled
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      growthPlans {
        nextToken
        __typename
      }
      vods {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteOperator = /* GraphQL */ `
  subscription OnDeleteOperator {
    onDeleteOperator {
      id
      company_name
      trade
      corporate_registry
      disabled
      disabledDate
      headend
      evolution
      gatewayId
      econoGroup
      externalDisabled
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      growthPlans {
        nextToken
        __typename
      }
      vods {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateRegion = /* GraphQL */ `
  subscription OnCreateRegion {
    onCreateRegion {
      id
      operatorId
      technology
      disabled
      disabledDate
      mandatory
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      municipality {
        id
        code
        name
        state
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateRegion = /* GraphQL */ `
  subscription OnUpdateRegion {
    onUpdateRegion {
      id
      operatorId
      technology
      disabled
      disabledDate
      mandatory
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      municipality {
        id
        code
        name
        state
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteRegion = /* GraphQL */ `
  subscription OnDeleteRegion {
    onDeleteRegion {
      id
      operatorId
      technology
      disabled
      disabledDate
      mandatory
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      municipality {
        id
        code
        name
        state
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateVOD = /* GraphQL */ `
  subscription OnCreateVOD {
    onCreateVOD {
      id
      name
      broadcasterId
      disabled
      disabledDate
      createdAt
      updatedAt
      operators {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateVOD = /* GraphQL */ `
  subscription OnUpdateVOD {
    onUpdateVOD {
      id
      name
      broadcasterId
      disabled
      disabledDate
      createdAt
      updatedAt
      operators {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteVOD = /* GraphQL */ `
  subscription OnDeleteVOD {
    onDeleteVOD {
      id
      name
      broadcasterId
      disabled
      disabledDate
      createdAt
      updatedAt
      operators {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateSVA = /* GraphQL */ `
  subscription OnCreateSVA {
    onCreateSVA {
      id
      name
      broadcasterId
      incTable {
        userbase
        value
        discount
        __typename
      }
      isIncTable
      disabled
      disabledDate
      urn
      createdAt
      updatedAt
      growthPlans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateSVA = /* GraphQL */ `
  subscription OnUpdateSVA {
    onUpdateSVA {
      id
      name
      broadcasterId
      incTable {
        userbase
        value
        discount
        __typename
      }
      isIncTable
      disabled
      disabledDate
      urn
      createdAt
      updatedAt
      growthPlans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteSVA = /* GraphQL */ `
  subscription OnDeleteSVA {
    onDeleteSVA {
      id
      name
      broadcasterId
      incTable {
        userbase
        value
        discount
        __typename
      }
      isIncTable
      disabled
      disabledDate
      urn
      createdAt
      updatedAt
      growthPlans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateGrowthPlan = /* GraphQL */ `
  subscription OnCreateGrowthPlan {
    onCreateGrowthPlan {
      id
      svaId
      operatorId
      combo
      disabled
      disabledDate
      planTable {
        userbase
        month
        year
        __typename
      }
      clientNumber
      contractNumber
      startDate
      isB2B
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      sva {
        id
        name
        broadcasterId
        isIncTable
        disabled
        disabledDate
        urn
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateGrowthPlan = /* GraphQL */ `
  subscription OnUpdateGrowthPlan {
    onUpdateGrowthPlan {
      id
      svaId
      operatorId
      combo
      disabled
      disabledDate
      planTable {
        userbase
        month
        year
        __typename
      }
      clientNumber
      contractNumber
      startDate
      isB2B
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      sva {
        id
        name
        broadcasterId
        isIncTable
        disabled
        disabledDate
        urn
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteGrowthPlan = /* GraphQL */ `
  subscription OnDeleteGrowthPlan {
    onDeleteGrowthPlan {
      id
      svaId
      operatorId
      combo
      disabled
      disabledDate
      planTable {
        userbase
        month
        year
        __typename
      }
      clientNumber
      contractNumber
      startDate
      isB2B
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      sva {
        id
        name
        broadcasterId
        isIncTable
        disabled
        disabledDate
        urn
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMunicipality = /* GraphQL */ `
  subscription OnCreateMunicipality {
    onCreateMunicipality {
      id
      code
      name
      state
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateMunicipality = /* GraphQL */ `
  subscription OnUpdateMunicipality {
    onUpdateMunicipality {
      id
      code
      name
      state
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteMunicipality = /* GraphQL */ `
  subscription OnDeleteMunicipality {
    onDeleteMunicipality {
      id
      code
      name
      state
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateBroadcaster = /* GraphQL */ `
  subscription OnCreateBroadcaster {
    onCreateBroadcaster {
      id
      company_name
      trade
      corporate_registry
      flexibleTables
      flexiblePriceTableB2B
      disabled
      disabledDate
      layout
      layoutSva
      layoutVod
      increasePercentage
      hboMaxMgMetaTable {
        porte
        base
        mg
        __typename
      }
      createdAt
      updatedAt
      vod {
        nextToken
        __typename
      }
      svas {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      channels {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateBroadcaster = /* GraphQL */ `
  subscription OnUpdateBroadcaster {
    onUpdateBroadcaster {
      id
      company_name
      trade
      corporate_registry
      flexibleTables
      flexiblePriceTableB2B
      disabled
      disabledDate
      layout
      layoutSva
      layoutVod
      increasePercentage
      hboMaxMgMetaTable {
        porte
        base
        mg
        __typename
      }
      createdAt
      updatedAt
      vod {
        nextToken
        __typename
      }
      svas {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      channels {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteBroadcaster = /* GraphQL */ `
  subscription OnDeleteBroadcaster {
    onDeleteBroadcaster {
      id
      company_name
      trade
      corporate_registry
      flexibleTables
      flexiblePriceTableB2B
      disabled
      disabledDate
      layout
      layoutSva
      layoutVod
      increasePercentage
      hboMaxMgMetaTable {
        porte
        base
        mg
        __typename
      }
      createdAt
      updatedAt
      vod {
        nextToken
        __typename
      }
      svas {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      channels {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateLineup = /* GraphQL */ `
  subscription OnCreateLineup {
    onCreateLineup {
      id
      channelId
      contractId
      disabled
      disabledDate
      penetration
      createdAt
      updatedAt
      contract {
        id
        broadcasterId
        operatorId
        contract_number
        customer_code
        distribution
        packing
        startDate
        billingCode
        createdAt
        updatedAt
        __typename
      }
      channel {
        id
        name
        broadcasterId
        disabled
        disabledDate
        paytv
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateLineup = /* GraphQL */ `
  subscription OnUpdateLineup {
    onUpdateLineup {
      id
      channelId
      contractId
      disabled
      disabledDate
      penetration
      createdAt
      updatedAt
      contract {
        id
        broadcasterId
        operatorId
        contract_number
        customer_code
        distribution
        packing
        startDate
        billingCode
        createdAt
        updatedAt
        __typename
      }
      channel {
        id
        name
        broadcasterId
        disabled
        disabledDate
        paytv
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteLineup = /* GraphQL */ `
  subscription OnDeleteLineup {
    onDeleteLineup {
      id
      channelId
      contractId
      disabled
      disabledDate
      penetration
      createdAt
      updatedAt
      contract {
        id
        broadcasterId
        operatorId
        contract_number
        customer_code
        distribution
        packing
        startDate
        billingCode
        createdAt
        updatedAt
        __typename
      }
      channel {
        id
        name
        broadcasterId
        disabled
        disabledDate
        paytv
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateVod2Operator = /* GraphQL */ `
  subscription OnCreateVod2Operator {
    onCreateVod2Operator {
      id
      vodId
      operatorId
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      vod {
        id
        name
        broadcasterId
        disabled
        disabledDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateVod2Operator = /* GraphQL */ `
  subscription OnUpdateVod2Operator {
    onUpdateVod2Operator {
      id
      vodId
      operatorId
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      vod {
        id
        name
        broadcasterId
        disabled
        disabledDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteVod2Operator = /* GraphQL */ `
  subscription OnDeleteVod2Operator {
    onDeleteVod2Operator {
      id
      vodId
      operatorId
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      vod {
        id
        name
        broadcasterId
        disabled
        disabledDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateContract = /* GraphQL */ `
  subscription OnCreateContract {
    onCreateContract {
      id
      broadcasterId
      operatorId
      contract_number
      customer_code
      distribution
      packing
      startDate
      billingCode
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        flexiblePriceTableB2B
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineup {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateContract = /* GraphQL */ `
  subscription OnUpdateContract {
    onUpdateContract {
      id
      broadcasterId
      operatorId
      contract_number
      customer_code
      distribution
      packing
      startDate
      billingCode
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        flexiblePriceTableB2B
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineup {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteContract = /* GraphQL */ `
  subscription OnDeleteContract {
    onDeleteContract {
      id
      broadcasterId
      operatorId
      contract_number
      customer_code
      distribution
      packing
      startDate
      billingCode
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        flexiblePriceTableB2B
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineup {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateChannel = /* GraphQL */ `
  subscription OnCreateChannel {
    onCreateChannel {
      id
      name
      broadcasterId
      disabled
      disabledDate
      paytv
      createdAt
      updatedAt
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        flexiblePriceTableB2B
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineups {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateChannel = /* GraphQL */ `
  subscription OnUpdateChannel {
    onUpdateChannel {
      id
      name
      broadcasterId
      disabled
      disabledDate
      paytv
      createdAt
      updatedAt
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        flexiblePriceTableB2B
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineups {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteChannel = /* GraphQL */ `
  subscription OnDeleteChannel {
    onDeleteChannel {
      id
      name
      broadcasterId
      disabled
      disabledDate
      paytv
      createdAt
      updatedAt
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        flexiblePriceTableB2B
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineups {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateGeoJson = /* GraphQL */ `
  subscription OnCreateGeoJson {
    onCreateGeoJson {
      id
      code
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateGeoJson = /* GraphQL */ `
  subscription OnUpdateGeoJson {
    onUpdateGeoJson {
      id
      code
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteGeoJson = /* GraphQL */ `
  subscription OnDeleteGeoJson {
    onDeleteGeoJson {
      id
      code
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateReportWarning = /* GraphQL */ `
  subscription OnCreateReportWarning {
    onCreateReportWarning {
      id
      period
      operatorId
      broadcasterId
      regionId
      warning
      sheet
      comments {
        author
        contents
        __typename
      }
      warningType
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      region {
        id
        operatorId
        technology
        disabled
        disabledDate
        mandatory
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateReportWarning = /* GraphQL */ `
  subscription OnUpdateReportWarning {
    onUpdateReportWarning {
      id
      period
      operatorId
      broadcasterId
      regionId
      warning
      sheet
      comments {
        author
        contents
        __typename
      }
      warningType
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      region {
        id
        operatorId
        technology
        disabled
        disabledDate
        mandatory
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteReportWarning = /* GraphQL */ `
  subscription OnDeleteReportWarning {
    onDeleteReportWarning {
      id
      period
      operatorId
      broadcasterId
      regionId
      warning
      sheet
      comments {
        author
        contents
        __typename
      }
      warningType
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      region {
        id
        operatorId
        technology
        disabled
        disabledDate
        mandatory
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
